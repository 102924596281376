import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ContentContainer,
  GradientContainer,
  GradientText,
  ShareButton,
} from "./ReferralBanner.styled";
import PaperPlaneImg from "../../Assets/Img/paperplane-icon.png";
import { FlexContainer } from "../Flex/Flex.styled";
import Text from "../Text";
import Icon from "../Icon";
import { authenticationUrl } from "../../Constants/ConfigureApi";
import { useSelector } from "react-redux";
import { userIdSelector } from "../../Redux/User/User.slice";
import { getInvitationCode } from "../../Modules/Common/application/getInvitationCode";
import { createApiInvitationCodeRepository } from "../../Modules/Common/infraestructure/ApiInvitationCodeRepository";

export const ReferralBanner = () => {
  const [t] = useTranslation("global");
  const userIdRedux = useSelector(userIdSelector);
  const [invitationCode, setInvitationCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const userId = useMemo(() => userIdRedux || "", [userIdRedux]);

  const getCode = useCallback(async () => {
    if (!userId) return;

    setLoading(true);
    const code = await getInvitationCode(createApiInvitationCodeRepository())(
      userId
    );
    setInvitationCode(code);
    setLoading(false);
  }, [userId]);

  const onShare = () => {
    if (navigator.share) {
      const shareOptions = {
        title: t("Rewards.PopUp.LinkTitle"),
        text: t("Rewards.PopUp.LinkText"),
        url: `${authenticationUrl}/sign-up?referral=${invitationCode}`,
      };

      navigator.share(shareOptions);
    }
  };

  useEffect(() => {
    getCode();
  }, [userId]);

  return (
    <GradientContainer>
      <ContentContainer alignItems="center" direction="row" gap="10px">
        <img src={PaperPlaneImg} />
        <FlexContainer direction="column" gap="4px">
          <div>
            <GradientText align="left" size={2} margin={0}>
              {t("Rewards.Banner.ShareCodeTitle")}
            </GradientText>
            <Text
              align="left"
              size={0.5}
              margin={0}
              color="white"
              lineHeight="12px"
            >
              {t("Rewards.Banner.ShareCodeSubtitle")}
            </Text>
          </div>

          {!loading && (
            <ShareButton gap="6px" onClick={onShare}>
              <Text size={0.5} color="white" margin={0}>
                {t("Rewards.Banner.ShareButton")}
              </Text>

              <Icon icon="share" color="white" />
            </ShareButton>
          )}
        </FlexContainer>
      </ContentContainer>
    </GradientContainer>
  );
};
