import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

// Own reducers
import { currenciesSlice } from "../Currencies/Currencies.slice";
import { PersonsKYCSlice } from "../PersonsKYC/PersonsKYC.slice";
import { themeSlice } from "../Theme";
import { languageSlice } from "../Translate";
import { tokenSlice } from "../Token/Token.slice";
import { userIdSlice } from "../User/User.slice";
import { QuotationSlice } from "../Quotation/Quotation.slice";
import { PersonsCardSlice } from "../PersonsCard/PersonsCard.slice";
import { BeneficiarySlice } from "../Beneficiary";
import { PaymentCheckoutSlice } from "../PaymentCheckout/PaymentCheckout.slice";
import { PaymentAchSlice } from "../PaymentACH/PaymentACH.slice";
import { PaymentLinkedAccountSlice } from "../PaymentLinkedAccount/PaymentLinkedAccount.slice";
import { TransferOrdersSlice } from "../TransferOrders";
import { PaymentOrderVerifySlice } from "../PaymentOrder/PaymentOrderVerify.slice";
import { paymentOrderSlice } from "../PaymentOrder";
import { globalConfigSlice } from "../GlobalConfig/GlobalConfig.slice";
import { SendolaPayOrderSlice } from "../PaymentOrder/SendolaPayOrder.slice";
import { TokenValidationSlice } from "../TokenValidation/TokenValidation.slice";
import { GeolocationSlice } from "../Geolocation/Geolocation.slice";
import { DashboardSlice } from "../Dashboard/Dashboard.slice";
import { FeaturesSlice } from "../Features/Features.slice";
import { countrySlice } from "../Country/Country.slice";
import { UserFeatureSlice } from "../UserFeature/UserFeature.slice";
import { CouponsSlice } from "../Coupons/Coupons.slice";

const rootReducer = combineReducers({
  [themeSlice.name]: themeSlice.reducer,
  [languageSlice.name]: languageSlice.reducer,
  [currenciesSlice.name]: currenciesSlice.reducer,
  [PersonsKYCSlice.name]: PersonsKYCSlice.reducer,
  [tokenSlice.name]: tokenSlice.reducer,
  [userIdSlice.name]: userIdSlice.reducer,
  [QuotationSlice.name]: QuotationSlice.reducer,
  [PersonsCardSlice.name]: PersonsCardSlice.reducer,
  [BeneficiarySlice.name]: BeneficiarySlice.reducer,
  [PaymentCheckoutSlice.name]: PaymentCheckoutSlice.reducer,
  [PaymentAchSlice.name]: PaymentAchSlice.reducer,
  [PaymentLinkedAccountSlice.name]: PaymentLinkedAccountSlice.reducer,
  [TransferOrdersSlice.name]: TransferOrdersSlice.reducer,
  [paymentOrderSlice.name]: paymentOrderSlice.reducer,
  [PaymentOrderVerifySlice.name]: PaymentOrderVerifySlice.reducer,
  [globalConfigSlice.name]: globalConfigSlice.reducer,
  [SendolaPayOrderSlice.name]: SendolaPayOrderSlice.reducer,
  [TokenValidationSlice.name]: TokenValidationSlice.reducer,
  [GeolocationSlice.name]: GeolocationSlice.reducer,
  [DashboardSlice.name]: DashboardSlice.reducer,
  [FeaturesSlice.name]: FeaturesSlice.reducer,
  [UserFeatureSlice.name]: UserFeatureSlice.reducer,
  [countrySlice.name]: countrySlice.reducer,
  [CouponsSlice.name]: CouponsSlice.reducer,
});

const persistConfig = {
  key: "Sendola-WebApp",
  storage,
  whitelist: [
    userIdSlice.name,
    tokenSlice.name,
    PersonsKYCSlice.name,
    QuotationSlice.name,
    BeneficiarySlice.name,
    globalConfigSlice.name,
    GeolocationSlice.name,
    DashboardSlice.name,
    countrySlice.name,
    PaymentOrderVerifySlice.name,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
