import { useTranslation } from "react-i18next";
import {
  HightlightedText,
  HightlightStrong,
  ModalContainer,
  ModalHead,
  RoundNumberContainer,
  TermsLink,
  TextContainer,
} from "./ReferralModal.styled";
import PopupImageEs from "../../../Assets/Img/popup-image-es.png";
import PopupImageEn from "../../../Assets/Img/popup-image-en.png";
import Icon from "../../Icon";
import { FlexContainer } from "../../Flex/Flex.styled";
import { OverLay } from "./ReferralModal.styled";
import Text from "../../Text";
import Button from "../../Button";
import { authenticationUrl } from "../../../Constants/ConfigureApi";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createApiInvitationCodeRepository } from "../../../Modules/Common/infraestructure/ApiInvitationCodeRepository";
import { useSelector } from "react-redux";
import { userIdSelector } from "../../../Redux/User/User.slice";
import { getInvitationCode } from "../../../Modules/Common/application/getInvitationCode";

interface ReferralModalProps {
  show: boolean;
  handleClose: () => void;
}

export const ReferralModal = ({ show, handleClose }: ReferralModalProps) => {
  const userIdRedux = useSelector(userIdSelector);
  const [t, i18n] = useTranslation("global");
  const [invitationCode, setInvitationCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const lang = i18n.language;

  const userId = useMemo(() => userIdRedux || "", [userIdRedux]);

  const getCode = useCallback(async () => {
    if (!userId) return;

    setLoading(true);
    const code = await getInvitationCode(createApiInvitationCodeRepository())(
      userId
    );
    setInvitationCode(code);
    setLoading(false);
  }, [userId]);

  const onClickClose = () => {
    if (handleClose) handleClose();
  };

  const onShare = () => {
    if (navigator.share) {
      const shareOptions = {
        title: t("Rewards.PopUp.LinkTitle"),
        text: t("Rewards.PopUp.LinkText"),
        url: `${authenticationUrl}/sign-up?referral=${invitationCode}`,
      };

      navigator.share(shareOptions);
    }
  };

  useEffect(() => {
    getCode();
  }, [userId]);

  return (
    <OverLay hidden={!show} onClick={onClickClose}>
      <ModalContainer onClick={(event) => event.stopPropagation()}>
        <ModalHead>
          <Icon size="medium" icon="iconX" onClick={onClickClose} />
        </ModalHead>
        <img
          src={lang === "es" ? PopupImageEs : PopupImageEn}
          alt="Referral program image"
          width="100%"
        />

        <TextContainer>
          <FlexContainer direction="column" alignItems="center" gap="10px">
            <Text margin="0 0 15px 0" size={2} color="white">
              {t("Rewards.PopUp.Title") + " "}
              <HightlightedText>
                {t("Rewards.PopUp.TitleHighlight")}
              </HightlightedText>
            </Text>

            <FlexContainer gap="10px" w="100%" alignItems="center">
              <RoundNumberContainer>
                <Text margin={0} size={1} weight={600} color="white">
                  1
                </Text>
              </RoundNumberContainer>
              <Text
                align="left"
                margin={0}
                color="white"
                size={0.5}
                weight={300}
              >
                <HightlightStrong
                  dangerouslySetInnerHTML={{
                    __html: t("Rewards.PopUp.Reward1"),
                  }}
                />
              </Text>
            </FlexContainer>

            <FlexContainer gap="10px" w="100%" alignItems="center">
              <RoundNumberContainer>
                <Text margin={0} size={1} weight={600} color="white">
                  2
                </Text>
              </RoundNumberContainer>
              <Text
                align="left"
                margin={0}
                color="white"
                size={0.5}
                weight={300}
              >
                <HightlightStrong
                  dangerouslySetInnerHTML={{
                    __html: t("Rewards.PopUp.Reward2"),
                  }}
                />
              </Text>
            </FlexContainer>

            <FlexContainer gap="10px" w="100%" alignItems="center">
              <RoundNumberContainer>
                <Text margin={0} size={1} weight={600} color="white">
                  3
                </Text>
              </RoundNumberContainer>
              <Text
                align="left"
                margin={0}
                color="white"
                size={0.5}
                weight={300}
              >
                <HightlightStrong
                  dangerouslySetInnerHTML={{
                    __html: t("Rewards.PopUp.Reward3"),
                  }}
                />
              </Text>
            </FlexContainer>

            <Text margin="5px" size={2} color="white" weight={600}>
              {t("Rewards.PopUp.Callout")}
            </Text>

            <Button
              variant="primary"
              sizeButton="large"
              sizeText="medium"
              sizeIcon="medium"
              iconButtonLeft="share"
              text={t("Rewards.PopUp.Button")}
              padding="8px"
              onClick={onShare}
              disabled={loading}
            />

            <FlexContainer justify="center" alignItems="center">
              <Icon icon="circleInfo" size="medium" color="white" />
              <a href={t("Rewards.TermsAndConditions")}>
                <TermsLink margin="5px" size={0.5} color="white">
                  {t("Rewards.PopUp.Terms")}
                </TermsLink>
              </a>
            </FlexContainer>
          </FlexContainer>
        </TextContainer>
      </ModalContainer>
    </OverLay>
  );
};
