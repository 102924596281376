import { useDispatch, useSelector } from "react-redux";
import { Errors } from "../Modules/Common/domain/Errors";
import { getCoupons } from "../Modules/Rewards/application/getCoupons";
import { createApiCouponRepository } from "../Modules/Rewards/infrastructure/ApiCouponRepository";
import { useStatus } from "./useStatus";
import { CouponsSelector, CouponsSlice } from "../Redux/Coupons/Coupons.slice";
import { AppDispatch } from "../Redux/Store";
import { QuotationSlice } from "../Redux/Quotation";
import { PersonsKYCSelector } from "../Redux/PersonsKYC";
import isEmpty from "lodash.isempty";

export const useCoupons = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, startRequest, endRequest } = useStatus();
  const { couponsGrouped } = useSelector(CouponsSelector).data;
  const personData = useSelector(PersonsKYCSelector).data;
  const person = personData?.personVeriff;

  const getUserCoupons = async () => {
    startRequest();
    try {
      if (!isEmpty(person) && !person.applyFeePromotion) {
        const couponsRes = await getCoupons(createApiCouponRepository())();

        dispatch(CouponsSlice.actions.setCoupons(couponsRes));
        if (couponsRes.couponsGrouped?.length > 0) {
          dispatch(
            QuotationSlice.actions.setCouponToApply(
              couponsRes.couponsGrouped[0]
            )
          );
        } else {
          dispatch(QuotationSlice.actions.setCouponToApply(undefined));
        }

        endRequest();
        return couponsRes;
      } else {
        dispatch(CouponsSlice.actions.setCoupons([]));
        dispatch(QuotationSlice.actions.setCouponToApply(undefined));
        endRequest();
        return [];
      }
    } catch (error) {
      dispatch(CouponsSlice.actions.setCoupons([]));
      dispatch(QuotationSlice.actions.setCouponToApply(undefined));
      endRequest({ error: error as Errors });
    }
  };

  return {
    isLoading,
    couponsGrouped,
    getUserCoupons,
  };
};
