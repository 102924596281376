import { useState } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../Redux/Store";
import { PersonsKYCSlice } from "../Redux/PersonsKYC";

import {
  CreateIDDocumentPayload,
  Person,
  SelfieStatusResponse,
} from "../Modules/Person/domain/Person";
import {
  getPerson,
  getSelfieStatus,
} from "../Modules/Person/application/getPerson";
import { createApiPersonRepository } from "../Modules/Person/infrastructure/ApiPersonRepository";
import { Errors } from "../Modules/Common/domain/Errors";
import { globalConfigSlice } from "../Redux/GlobalConfig/GlobalConfig.slice";
import { useStatus } from "./useStatus";
import { updateIDDocument } from "../Modules/Person/application/updateIDDocument";

export const usePerson = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, startRequest, endRequest } = useStatus();
  const [person, setPerson] = useState({} as Person);
  const [selfieStatus, setSelfieStatus] = useState<
    SelfieStatusResponse | undefined
  >();
  const [errors, setErros] = useState({} as Errors);

  const getCurrentPerson = async () => {
    startRequest();
    try {
      const response = await getPerson(createApiPersonRepository())();
      dispatch(PersonsKYCSlice.actions.setPersonVeriff(response));

      if (response?.codeResponse) {
        const config = response?.codeResponse;
        dispatch(
          globalConfigSlice.actions.setConfig({
            customerId: config?.customerId,
            customerName: config?.customerName,
            invitationCode: config?.invitationCode,
            gpTier: config?.gpTier,
            oneTier: config?.oneTier,
          })
        );
      }

      setPerson(response);
    } catch (error: any) {
      console.error(error);
      setErros(error);
    } finally {
      endRequest();
    }
  };

  const updateIdentificationDocument = async (
    payload: CreateIDDocumentPayload
  ) => {
    startRequest();
    try {
      const response = await updateIDDocument(createApiPersonRepository())(
        payload
      );

      endRequest();
      return response;
    } catch (error) {
      endRequest({ error: error as Errors });
    }
  };

  const getPersonSelfieStatus = async () => {
    startRequest();
    try {
      const response = await getSelfieStatus(createApiPersonRepository())();

      endRequest();
      setSelfieStatus(response);
      return response;
    } catch (error) {
      endRequest({ error: error as Errors });
    }
  };

  return {
    person,
    selfieStatus,
    errors,
    isLoading,
    getCurrentPerson,
    updateIdentificationDocument,
    getPersonSelfieStatus,
  };
};
