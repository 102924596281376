import { SizesKey } from "../../Utilities/Sizes";
import Text from "./Text.component";
import { SuperscriptStyled } from "./Text.styled";

interface SuperscriptProps {
  note: string;
  size?: SizesKey;
  onClick?: () => void;
}

export const Superscript = ({
  note,
  size = "default",
  onClick,
}: SuperscriptProps) => {
  return (
    <SuperscriptStyled
      size={size}
      display="inline"
      verticalAlign="super"
      as="span"
    >
      {note}
    </SuperscriptStyled>
  );
};
