import styled from "styled-components";
import { theme } from "styled-tools";

export const FlagsContainer = styled.div`
  height: auto;
  max-height: 31.36px;
  position: relative;
  width: min-content;
  min-width: 34.21px;
  text-align: left;

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    box-shadow: inset 0px 4px 4px 0px ${theme("Black")}40;
    pointer-events: none;
  }

  img {
    border-radius: 50%;

    &:nth-child(1) {
      width: 31px;
      height: 31px;
    }

    &:nth-child(2) {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 13px;
      top: 15px;

      filter: drop-shadow(-8px -10px 3px 0px ${theme("Black")}40);
    }
  }
`;
