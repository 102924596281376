import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { ifProp, theme } from "styled-tools";

export const DashboardContent = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto;
  width: 100%;
  max-width: 1468px;
`;

export const QuoterSection = styled.div`
  box-sizing: border-box;
  padding: 0;
`;

export const DashboardCard = styled.div`
  box-sizing: border-box;
  border-radius: 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: center;
  position: relative;
  width: 100%;
  padding-bottom: 13px;
  z-index: 0;

  ${mediaQuery[Screen.mobile]} {
    background: ${theme("Background_01")};
    height: auto;
    width: 100%;
  }
`;

export const DashboardCardInner = styled.div`
  box-sizing: border-box;
  padding: 2rem 3rem;

  ${mediaQuery[Screen.mobile]} {
    padding: 0;
  }
`;

export const Section = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-around;
  flex-direction: row;
  padding: 24px;

  ${mediaQuery[Screen.mobile]} {
    flex-direction: column;
    padding: 16px;
    align-items: center;
  }
`;

export const SectionRow = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 16px 40px;

  ${mediaQuery[Screen.mobile]} {
    flex-direction: column;
  }
`;

export const SectionRowButton = styled.div`
  display: flex;
  align-content: end;
  justify-content: end;
  flex-direction: row;
  margin: 40px 0;

  ${mediaQuery[Screen.mobile]} {
    background-color: ${theme("Background_01")};
    border-radius: 50px;
    border-color: transparent;
    padding: 10px;
    align-content: center;
    justify-content: center;
    margin: 0;

    div {
      width: 100%;
    }
  }
`;

export const SectionColumn = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const CurrentBalance = styled.div<{
  $accountState: boolean;
}>`
  border: 2px red solid;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: start;

  height: auto;
  width: 100%;

  p {
    margin: 8px 0;
    text-align: left;
  }

  ${mediaQuery[Screen.mobile]} {
    background: ${ifProp(
      "$accountState",
      theme("Primary_02"),
      theme("Primary_05")
    )};
    border-radius: 50px;
    height: auto;
  }
`;

export const InfoCard = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin: 5px 0;

  ${mediaQuery[Screen.mobile]} {
    flex-direction: column;
    align-items: start;
    margin: 0;
    margin-bottom: 20px;

    p {
      line-height: 0.1;
    }
  }
`;

export const LabelCard = styled.div`
  border: 1px solid ${theme("Background_01")};
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 5px 12px;
  width: 100%;

  svg {
    cursor: pointer;
  }
`;

export const ActionsButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 24px;

  button {
    max-width: 270px;
    width: 100%;
  }
`;

export const ButtonLink = styled.div`
  background: ${theme("Primary_02")};
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 32px;
  font-weight: 600;
  color: ${theme("Background_01")};
  text-align: center;
  padding: 10px;
  height: 50px;
  width: 330px;

  img {
    width: 50px;
    height: 50px;
  }

  &:hover {
    opacity: 0.95;
  }

  @media only screen and (max-width: 980px) {
    font-size: 20px;
    width: 85%;

    img {
      width: 40px;
      height: 40px;
    }
  }
`;

export const RecentTransactions = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  text-align: left;
  min-height: 100px;
  width: auto;
  max-width: 560px;
  margin: 0 auto;

  ${mediaQuery[Screen.mobile]} {
    h2 {
      text-align: center;
    }
  }
`;

export const Contacts = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  text-align: left;
  min-height: 100px;
  margin: 0 auto;
  width: auto;
  max-width: 560px;
  margin: 0 auto;

  ${mediaQuery[Screen.mobile]} {
    h2 {
      text-align: center;
    }
  }
`;

export const NameContent = styled.div`
  max-width: 600px;
  display: flex;
  justify-content: start;
  flex-direction: row;
  text-align: left;
  width: 80%;

  h2 {
    margin-bottom: 0;
    margin-right: 10px;
    line-height: 24px;
  }

  p {
    text-align: left;
  }

  ${mediaQuery[Screen.mobile]} {
    margin-left: 0;
    max-width: 100%;
    text-align: center;

    p {
      text-align: center;
    }
  }

  ${mediaQuery[Screen.mobile]} {
    text-align: left;
    width: 100%;
  }
`;

export const NameContentIcon = styled(NameContent)`
  max-width: 100%;
  width: 100%;
  flex-direction: row;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  h2 {
    margin: 0 10px 0 0;
  }
`;

export const NameContentText = styled(NameContent)`
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-top: 10px;

  p {
    text-align: center;
    width: 400px;
  }

  ${mediaQuery[Screen.mobile]} {
    p {
      width: 80%;
    }
  }

  b {
    color: ${theme("Content_UX_01")};
  }
`;

export const LoadMoreContent = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-align: left;
  padding: 40px;

  div {
    margin-right: 8px;
  }

  a {
    font-size: 20px;
    font-weight: 500;
  }

  ${mediaQuery[Screen.mobile]} {
    text-align: center;
    justify-content: center;
  }
`;

export const RowEmpty = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 30px 100px;
  margin-bottom: 230px;

  ${mediaQuery[Screen.mobile]} {
    text-align: center;
    margin-bottom: 100px;
  }
`;

export const ActionsIcons = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-around;
  flex-direction: row;
  width: 50px;
`;

export const ButtonContactUs = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  button {
    width: 100%;
    max-width: 200px;
    align-content: center;
  }
`;

export const TransactioCardWrapper = styled.div`
  cursor: pointer;
`;

export const VeriffCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 366px;
  border-radius: 24px;
  background-color: ${theme("Green_01")};
`;

export const popUpProfile = styled.div`
  padding-top: 40px;
  h1 {
    margin-top: 28px;
  }
`;

export const SwitchColumn = styled.div`
  flex-direction: column;
`;
