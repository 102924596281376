import { createConfigApiApp } from "../../../Services/ApiApp";
import {
  InvitationCodeRepository,
  InvitationCodeResponse,
} from "../domain/InvitationCode";

const client = createConfigApiApp();
const subdivisionPrefixService = "/rewards";

export const createApiInvitationCodeRepository =
  (): InvitationCodeRepository => {
    return { getInvitationCode };
  };

const getInvitationCode = async (userId: string): Promise<string> => {
  const response = await client.get<InvitationCodeResponse>(
    `${subdivisionPrefixService}/invitation-code/${userId}`
  );
  return response.data?.data?.code || "";
};
