import { IconsFontType } from "../../Models/IconInterface";
import { SelectOption } from "../../Models/Select";
import Button from "../Button";
import { GradientCard } from "../Card/Card.styled";
import { FlexContainer } from "../Flex/Flex.styled";
import IconFont from "../IconFont";
import Text from "../Text";
import { Superscript } from "../Text/Superscript.component";

interface CardSelectorProps {
  options: SelectOption<
    string,
    { iconName?: IconsFontType; superscript?: string }
  >[];
  value?: SelectOption<
    string,
    { iconName?: IconsFontType; superscript?: string }
  >["value"];
  onChange?: (
    option: SelectOption<
      string,
      { iconName?: IconsFontType; superscript?: string }
    >
  ) => void;
}

export const CardSelector = ({
  options,
  value,
  onChange,
}: CardSelectorProps) => {
  return (
    <FlexContainer gap="8px" justify="space-between">
      {options.map((opt, index) => (
        <Button
          key={index}
          variant="transparent"
          padding="0px"
          sizeButton="full"
          onClick={() => onChange && onChange(opt)}
        >
          <GradientCard
            active={value === opt.value}
            borderColor="gray"
            padding="7px 5px"
            borderRadius="12px"
          >
            <FlexContainer direction="column" alignItems="center" gap="4px">
              {opt.iconName && (
                <IconFont name={opt.iconName} color="black" size="medium" />
              )}
              <Text size={0.5} lineHeight="21px" weight={400} margin={0}>
                {opt.label}
                {opt.superscript && <Superscript note={opt.superscript} />}
              </Text>
            </FlexContainer>
          </GradientCard>
        </Button>
      ))}
    </FlexContainer>
  );
};
