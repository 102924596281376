import styled from "styled-components";
import { PaymentMethodType } from "../../../../Modules/Common/domain/PaymentMethods";
import { switchProp, theme } from "styled-tools";
import { FlexContainer } from "../../../Flex/Flex.styled";
import { iconWithGradientSecondary } from "../../../../Contexts/ThemeProvider/IconFont";

export const PaymentMethodRadioContainer = styled(FlexContainer)`
  cursor: pointer;
`;

export const PaymentMethodTypeTag = styled.span<{ type?: PaymentMethodType }>`
  border-radius: 16px;
  padding: 5px;
  font-size: 12px;
  line-height: 8px;
  text-align: center;

  color: ${switchProp("type", {
    express: theme("Primary_12"),
    "economy-express": theme("Primary_Blue"),
    "economy-minutes": theme("Primary_01_600"),
    economy: theme("Primary_01_600"),
  })};
  background-color: ${switchProp("type", {
    express: theme("Green_06"),
    "economy-express": theme("Alice_Blue"),
    "economy-minutes": theme("Primary_01_25"),
    economy: theme("Primary_01_25"),
  })};
`;

export const PaymentMethodCard = styled(FlexContainer)`
  flex: 1 1 auto;
  display: flex;
  gap: 12px;

  > img:nth-child(1) {
    width: 32px;
    height: 32px;
  }
`;

export const BankSupportedLogoContainer = styled(FlexContainer)`
  width: auto;
  height: 17px;
  padding: 4px;
`;

export const PlaidLogoElement = styled.div`
  background: ${iconWithGradientSecondary};
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SendolaPayLogoElement = styled(FlexContainer)`
  width: auto;
  align-items: center;
  gap: 3px;

  img:nth-child(1) {
    border-radius: 50%;
    background-color: ${theme("Primary_19")};
    padding: 5px;
    max-width: 40px;
    height: 40px;
    object-fit: contain;
    object-position: center;
  }

  img:nth-child(3) {
    max-width: 42px;
  }
`;
