import { useSelector } from "react-redux";
import { QuotationSelector } from "../../../Redux/Quotation/Quotation.slice";
import { BeneficiarySelector } from "../../../Redux/Beneficiary/Beneficiary.slice";
import { PersonsKYCSelector } from "../../../Redux/PersonsKYC";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { useTranslation } from "react-i18next";
import { ExchangeRateContainer } from "../QuoterCardAdvanced.styled";
import IconFont from "../../IconFont";
import { formatNumber } from "../../../Utilities/NumberUtils";
import { QuoteSummaryContainer } from "./QuoteSummary.styled";
import { Flags } from "../../Flags/Flags.component";
import { SenderExchangeRates } from "../../../Modules/Common/domain/ExchangeRates";
import { countrySelector } from "../../../Redux/Country/Country.slice";
import { useMemo } from "react";
import ZelleLogo from "../../../Assets/Img/zelle-rounded.png";
import SquareLogo from "../../../Assets/Img/square-rounded.png";
import PayPalLogo from "../../../Assets/Img/paypal-rounded.png";
import SendolaPayLogo from "../../../Assets/Img/sendola-pay-short.png";
import {
  PaymentMethod,
  PaymentMethods,
  PaymentMethodType,
  PlaidAccount,
  SquarePaymentMethod,
} from "../../../Modules/Common/domain/PaymentMethods";
import { maskInterbankKey } from "../../../Utilities/String";
import { ObjectFitImage } from "../../Image/Image.styled";
import theme from "../../../Contexts/ThemeProvider";
import { darkenColor } from "../../../Utilities/Colors";
import { useModal } from "../../../Hooks/useModal";
import { PaymentMethodTypeTag } from "../PaymentMethodSelect/PaymentRadio/PaymentRadio.styled";
import { CouponBar } from "../../CouponBar/CouponBar.component";
import { PaymentOrderCreated } from "../../../Modules/PaymentOrders/domain/PaymentOrderCreated";
import isEmpty from "lodash.isempty";

interface QuoteSummaryProps {
  paymentOrderCreated?: PaymentOrderCreated;
}

export const QuoteSummary = ({ paymentOrderCreated }: QuoteSummaryProps) => {
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const { modal, showModal } = useModal();

  const { countries } = useSelector(countrySelector);
  const {
    countryDestination,
    currentQuotation,
    currentDeliveryMethodQuote,
    currentPaymentMethod,
    currentPaymentDestination,
    paymentMethodSelected,
    couponToApply,
  } = useSelector(QuotationSelector).data;
  const { beneficiarySelected } = useSelector(BeneficiarySelector).data;
  const person = useSelector(PersonsKYCSelector).data?.personVeriff;
  const totalCost =
    person?.applyFeePromotion || couponToApply
      ? Number(currentPaymentMethod?.totalCost || "0") -
        Number(currentDeliveryMethodQuote?.fee || "0")
      : currentPaymentMethod?.totalCost || 0;

  const isD2B = currentDeliveryMethodQuote?.deliveryMethodCode === "D2B";
  const isCPU = currentDeliveryMethodQuote?.deliveryMethodCode === "CPU";

  const paymentMethodData = useMemo(() => {
    if (paymentMethodSelected?.paymentSource) {
      const isSendolaPay =
        paymentMethodSelected?.paymentSource === "Sendola Card";
      const isPlaid = paymentMethodSelected?.paymentSource === "Sendola Plaid";
      const isZelle = paymentMethodSelected?.paymentSource === "Zelle";
      const isSquare = paymentMethodSelected?.paymentSource === "Square";
      return {
        name: getPaymentMethodName(paymentMethodSelected),
        type: (isSendolaPay || isPlaid
          ? "economy"
          : isZelle
          ? "economy-express"
          : isSquare
          ? "express"
          : "economy-minutes") as PaymentMethodType,
        logo: getLogoFromPaymentMethod(paymentMethodSelected.paymentSource),
        backgroundLogo: isPlaid
          ? (paymentMethodSelected as PlaidAccount)?.backgroundColor
          : isSendolaPay
          ? theme.White
          : undefined,
        gradientBackground: isPlaid
          ? darkenColor(
              (paymentMethodSelected as PlaidAccount)?.backgroundColor
            )
          : undefined,
        logoWidth: isSendolaPay ? "40px" : "32px",
        logoHeight: isSendolaPay ? "40px" : "32px",
      };
    }
  }, [paymentMethodSelected]);

  function getLogoFromPaymentMethod(
    paymentOrigin: PaymentMethods
  ): string | undefined {
    switch (paymentOrigin) {
      case "Zelle":
        return ZelleLogo;
      case "Square":
        return SquareLogo;
      case "PayPal":
        return PayPalLogo;
      case "Sendola Plaid":
        return (paymentMethodSelected as PlaidAccount).logoUrl;
      case "Sendola Card":
        return SendolaPayLogo;
      default:
        return undefined;
    }
  }

  function getPaymentMethodName(
    paymentMethod: PaymentMethod | PlaidAccount | SquarePaymentMethod
  ) {
    switch (paymentMethod.paymentSource) {
      case "Sendola Plaid":
        return (paymentMethod as PlaidAccount).bankName;
      case "Sendola Card":
        return "Sendola Pay";
      default:
        return paymentMethod.paymentSource;
    }
  }

  const openZelleInstructions = () => {
    let paymentOrder = {};

    if (paymentOrderCreated) {
      paymentOrder = {
        reference: paymentOrderCreated.mtcn,
        amount: paymentOrderCreated.payment.amount
          ? Number(paymentOrderCreated.payment.amount)
          : Number(paymentOrderCreated.instruction.origination.amount) +
            Number(paymentOrderCreated.instruction.quote.fee) +
            Number(paymentOrderCreated.instruction.quote.paymentMethodFee),
      };
    }

    showModal({
      modalType: "zelleInstructionsModal",
      finish: !isEmpty(paymentOrderCreated?.id),
      ...paymentOrder,
    });
  };

  return (
    <QuoteSummaryContainer direction="column" gap="16px">
      <FlexContainer direction="column" gap="4px">
        <Text size={1} lineHeight="24px" align="left" weight={600} margin={0}>
          {tSendMoney("AmountToSend")}:
        </Text>
        <FlexContainer gap="12px" alignItems="start">
          <Flags>
            <img src={SenderExchangeRates[0].icon} />
            <img
              src={
                countries.find((c) => c.countryCode === countryDestination)
                  ?.icon
              }
            />
          </Flags>
          <FlexContainer direction="column">
            <Text size={1} weight={500} lineHeight="24px">
              {currentQuotation?.amount}&nbsp;{currentQuotation?.originCurrency}
            </Text>
            <Text size={1} weight={500} lineHeight="24px" color="grey">
              {currentDeliveryMethodQuote?.amountToReceive}&nbsp;
              {currentQuotation?.destinationCurrency}
            </Text>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <hr />
      <FlexContainer direction="column" gap="8px">
        <Text size={1} lineHeight="24px" align="left" weight={600} margin={0}>
          {tSendMoney("RecipientTitle")}:
        </Text>
        <FlexContainer gap="12px">
          {isD2B && (
            <IconFont
              name="building-bank"
              color="primaryStrong"
              weight={500}
              size="large"
            />
          )}
          {isCPU && (
            <IconFont
              name="hand-coin"
              color="primaryStrong"
              weight={500}
              size="large"
            />
          )}
          <FlexContainer direction="column">
            <Text size={0.5} weight={500} lineHeight="24px">
              {beneficiarySelected?.fullName}
            </Text>
            <Text size={0.5} weight={500} lineHeight="14px" color="grey">
              {isD2B &&
                `${
                  beneficiarySelected?.accountSelected?.bankName
                } ${maskInterbankKey(
                  beneficiarySelected?.accountSelected?.accountNumber || ""
                )}`}
              {isCPU && `${currentPaymentDestination?.destination}`}
            </Text>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <hr />
      <FlexContainer direction="column" gap="8px">
        <FlexContainer justify="space-between" alignItems="center">
          <Text size={1} lineHeight="24px" align="left" weight={600} margin={0}>
            {tSendMoney("PaymentMethod")}
          </Text>
          <PaymentMethodTypeTag type={paymentMethodData?.type}>
            {tSendMoney(`paymentMethods.types.${paymentMethodData?.type}`)}
          </PaymentMethodTypeTag>
        </FlexContainer>
        <FlexContainer gap="12px">
          <ObjectFitImage
            fit="contain"
            width={paymentMethodData?.logoWidth}
            height={paymentMethodData?.logoHeight}
            src={paymentMethodData?.logo}
            background={paymentMethodData?.backgroundLogo}
            gradientBackground={paymentMethodData?.gradientBackground}
            objectPosition="center"
            padding="5px"
            rounded
          />
          <FlexContainer direction="column" alignSelf="center">
            <Text size={0.5} weight={600} lineHeight="15px">
              {paymentMethodData?.name}
            </Text>
            {paymentMethodData?.name === "Zelle" && (
              <FlexContainer
                alignItems="center"
                gap="11px"
                onClick={openZelleInstructions}
              >
                <Text
                  weight={600}
                  size="default"
                  lineHeight="15px"
                  align="left"
                  color="zelle"
                >
                  {t("Zelle.howToCta")}
                </Text>
                {modal}
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <hr />
      <FlexContainer
        alignItems="baseline"
        justify="space-between"
        p="4px 8px 4px 8px"
        as={ExchangeRateContainer}
      >
        <Text size={0.5} lineHeight="21px" align="left" weight={600} margin={0}>
          {tSendMoney("ExchangeRate")}
        </Text>
        <Text
          size={0.5}
          lineHeight="21px"
          align="right"
          weight={600}
          margin={0}
        >
          $1.00 USD = ${currentDeliveryMethodQuote?.fx?.toFixed(4)}{" "}
          {currentQuotation?.destinationCurrency}
        </Text>
      </FlexContainer>
      <FlexContainer direction="column" gap="8px">
        <FlexContainer alignItems="baseline" justify="space-between">
          <Text
            size={0.5}
            lineHeight="21px"
            weight={500}
            color="grey"
            margin={0}
          >
            {tSendMoney("TransferFees")}:
          </Text>
          {person?.applyFeePromotion || couponToApply ? (
            <FlexContainer w="max-content" alignItems="center" gap="6px">
              <Text
                size={0.5}
                lineHeight="21px"
                weight={500}
                color="solid_2"
                textDecoration="line-through"
              >
                $
                {formatNumber(currentDeliveryMethodQuote?.fee || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                USD
              </Text>
              <Text size={0.5} lineHeight="21px" weight={500}>
                $
                {formatNumber(0, {
                  minimumFractionDigits: 2,
                })}{" "}
                USD
              </Text>
            </FlexContainer>
          ) : (
            <Text size={0.5} lineHeight="21px" weight={500}>
              $
              {formatNumber(currentDeliveryMethodQuote?.fee || 0, {
                minimumFractionDigits: 2,
              })}{" "}
              USD
            </Text>
          )}
        </FlexContainer>
        {currentPaymentMethod && (
          <FlexContainer alignItems="baseline" justify="space-between">
            <Text
              size={0.5}
              lineHeight="21px"
              weight={500}
              color="grey"
              margin={0}
            >
              {tSendMoney("PaymentMethodFee")}:
            </Text>
            <Text size={0.5} lineHeight="21px" weight={500} margin={0}>
              $
              {formatNumber(currentPaymentMethod.paymentMethodFee || 0, {
                minimumFractionDigits: 2,
              })}{" "}
              USD
            </Text>
          </FlexContainer>
        )}
      </FlexContainer>
      <hr />
      <FlexContainer justify="space-between">
        <Text size={0.5} lineHeight="21px" weight={700} color="grey" margin={0}>
          {tSendMoney("TotalPayment")}:
        </Text>
        <Text
          size={0.5}
          lineHeight="21px"
          weight={500}
          color="magenta"
          margin={0}
        >
          $
          {formatNumber(totalCost, {
            minimumFractionDigits: 2,
          })}{" "}
          USD
        </Text>
      </FlexContainer>
      {person?.applyFeePromotion ? (
        <CouponBar couponCode="WELCOME-0TRANSFERFEE" />
      ) : (
        couponToApply && (
          <CouponBar couponCode={couponToApply.code || "REF-FRIENDS"} />
        )
      )}
    </QuoteSummaryContainer>
  );
};
