import { useTranslation } from "react-i18next";
import { SelectPlaidAccountModalProps } from "../../../Models/ModalInterface";
import { FlexContainer } from "../../Flex/Flex.styled";
import { PaymentMethodTypeTag } from "../../QuoterCardAdvanced/PaymentMethodSelect/PaymentRadio/PaymentRadio.styled";
import Text from "../../Text";
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import Modal from "../Modal.component";
import Button from "../../Button";
import { GradientCard } from "../../Card/Card.styled";
import { maskInterbankKey } from "../../../Utilities/String";
import { RecentlyUsedTag } from "../BeneficiaryModal/SelectBeneficiaryAccountModal.styled";
import IconFont from "../../IconFont";
import CurrencyService from "../../../Services/CurrencyService";
import { darkenColor } from "../../../Utilities/Colors";
import { AccountLogo, ObjectFitImage } from "../../Image/Image.styled";
import {
  PaymentMethod,
  PlaidAccount,
} from "../../../Modules/Common/domain/PaymentMethods";

export const SelectPlaidAccountModal = (
  props: SelectPlaidAccountModalProps
) => {
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      <FlexContainer justify="center" m="0 0 16px 0">
        <Text size={1} weight={600} lineHeight="24px" margin={0}>
          {props.title || tSendMoney("paymentMethods.plaid.title")}
        </Text>
      </FlexContainer>
      <FlexContainer m="0 0 19px 0">
        <PaymentMethodTypeTag type="economy">
          {tSendMoney(`paymentMethods.types.economy`)}
        </PaymentMethodTypeTag>
      </FlexContainer>
      <FlexContainer direction="column" gap="8px">
        {props.accounts?.map((account, index) => (
          <Button
            key={index}
            variant="transparent"
            padding="0px"
            sizeButton="full"
            onClick={() => {
              props.handleConfirm && props.handleConfirm(account);
              props.handleClose(true);
            }}
          >
            <GradientCard
              width="100%"
              padding="11px 15px"
              borderColor="muted"
              active={props.value?.id === account.id}
            >
              <FlexContainer
                justify="space-between"
                alignItems="center"
                flex="1 1 auto"
                gap="12px"
              >
                <AccountLogo
                  backgroundColor={
                    account.paymentSource === "Sendola Plaid"
                      ? (account as PlaidAccount).backgroundColor
                      : (account as PaymentMethod).backgroundBank
                  }
                  gradientBackgroundColor={darkenColor(
                    account.paymentSource === "Sendola Plaid"
                      ? (account as PlaidAccount).backgroundColor
                      : (account as PaymentMethod).backgroundBank
                  )}
                >
                  <ObjectFitImage
                    fit="contain"
                    width="52px"
                    height="52px"
                    src={
                      account.paymentSource === "Sendola Plaid"
                        ? (account as PlaidAccount).logoUrl
                        : (account as PaymentMethod).logoUrlBank
                    }
                  />
                </AccountLogo>
                <FlexContainer direction="column">
                  <Text
                    size={0.5}
                    weight={500}
                    lineHeight="20px"
                    align="left"
                    margin="0 0 4px 0"
                  >
                    {account.paymentSource === "Sendola Plaid"
                      ? (account as PlaidAccount).bankName
                      : (account as PaymentMethod).sponsorBank}
                  </Text>
                  <FlexContainer gap="4px">
                    <Text
                      display="inline"
                      size="default"
                      weight={400}
                      lineHeight="12px"
                      color="grey"
                    >
                      {tSendMoney("Balance")}
                    </Text>
                    <Text
                      display="inline"
                      size="default"
                      weight={600}
                      lineHeight="12px"
                      color="grey"
                    >
                      {CurrencyService.formatNumberToCurrency(
                        account.paymentSource === "Sendola Plaid"
                          ? (account as PlaidAccount).available
                          : (account as PaymentMethod).availableBalance
                      )}
                    </Text>
                  </FlexContainer>
                  <Text
                    size="default"
                    weight={400}
                    lineHeight="12px"
                    color="grey"
                  >
                    {maskInterbankKey(
                      account.paymentSource === "Sendola Plaid"
                        ? (account as PlaidAccount).account
                        : (account as PaymentMethod).accountNumber
                    )}
                  </Text>
                </FlexContainer>
                {index === 0 && (
                  <RecentlyUsedTag>
                    <Text
                      weight={500}
                      size={1}
                      lineHeight="15px"
                      color="white"
                      margin={0}
                    >
                      {t("Beneficiaries.RecentlyUsed")}
                    </Text>
                  </RecentlyUsedTag>
                )}
                <IconFont name="chevron-right" color="black" size="small" />
              </FlexContainer>
            </GradientCard>
          </Button>
        ))}
      </FlexContainer>
    </Modal>
  );
};
