import { Link } from "react-router-dom";
import styled, { CSSProperties } from "styled-components";
import { SizesKey, handleSizeType } from "../../Utilities/Sizes";
import { ifProp, prop, theme } from "styled-tools";
import { FontColor, handleColor } from "../../Utilities/Icons";

export const LinkStyled = styled(Link)<{
  variant?: string;
  size?: SizesKey;
  underline?: boolean;
  color?: FontColor;
  align?: CSSProperties["textAlign"];
}>`
  color: ${(props) =>
    props.variant === "white"
      ? props.theme.Background_01
      : props.variant === "primary"
      ? props.theme.Primary_01
      : props.color
      ? handleColor(props.color)
      : theme("Background_02")};
  cursor: pointer;
  font-family: "Poppins";
  font-size: ${(props) => (props.size ? handleSizeType(props.size) : "12px")};
  text-decoration: ${ifProp("underline", "underline", "none")};
  text-align: ${ifProp("align", prop("align"), "center")};

  &:hover {
    text-decoration: ${(props) =>
      props.variant === "white" ? "underline" : "none"};
  }
`;
