import { ZelleInstructionsModalProps } from "../../../Models/ModalInterface";
import Modal from "../Modal.component";
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { useTranslation } from "react-i18next";
import ZelleLogo from "../../../Assets/Img/zelle-wide-logo.png";
import ZelleWhiteLogo from "../../../Assets/Img/zelle-white.png";
import BannerBankWideLogo from "../../../Assets/Img/bannerbank-wide.png";
import IconFont from "../../IconFont";
import { StepLogo } from "./ZelleInstructionsModal.styled";
import {
  zelleLinkEn,
  zelleLinkEs,
  zelleSendolaAccount,
  waLinkEn,
  waLinkEs,
  bannerBankPlayStore,
  bannerBankAppStore,
  zellePlayStore,
  zelleAppStore,
} from "../../../Constants/Zelle";
import Button from "../../Button";
import { useState } from "react";
import { CopyTextToClipboard } from "../../../Utilities/CopyTextToClipboard";
import CurrencyService from "../../../Services/CurrencyService";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../../Redux/Translate";
import { isAndroid, isIOS } from "react-device-detect";
import Link from "../../Link";
import { VerticalDivider } from "../../Layout/Layout.styled";
import { openApp } from "../../../Utilities/Redirect";

export const ZelleInstructionsModal = (props: ZelleInstructionsModalProps) => {
  const [t] = useTranslation("global");
  const [copiedText, setCopiedText] = useState(false);
  const { language } = useSelector(selectorLanguage);

  const zelleRedirect = language === "en" ? zelleLinkEn : zelleLinkEs;
  const zelleSupportWA = language === "en" ? waLinkEn : waLinkEs;

  const copyTextMtcn = () => {
    setCopiedText(true);
    CopyTextToClipboard(props.reference);
    setTimeout(() => {
      setCopiedText(false);
    }, 1000);
  };

  const handleButtonZelle = () => {
    const androidDeepLink =
      "intent://#Intent;scheme=zelle;package=com.zellepay.zelle;end";
    const iosDeepLink = "zelle://";
    let finalDeepLink = zelleRedirect;
    let fallbackDeepLink = zelleRedirect;

    if (isAndroid) {
      finalDeepLink = androidDeepLink;
      fallbackDeepLink = zellePlayStore;
    } else if (isIOS) {
      finalDeepLink = iosDeepLink;
      fallbackDeepLink = zelleAppStore;
    }

    openApp(finalDeepLink, fallbackDeepLink);
  };

  const handleButtonBannerBank = () => {
    const androidDeepLink =
      "intent://#Intent;scheme=bannerbank3388;package=com.bannerbank3388.mobile;end";
    const iosDeepLink = "BB3388://";
    let finalDeepLink = zelleRedirect;
    let fallbackDeepLink = zelleRedirect;

    if (isAndroid) {
      finalDeepLink = androidDeepLink;
      fallbackDeepLink = bannerBankPlayStore;
    } else if (isIOS) {
      finalDeepLink = iosDeepLink;
      fallbackDeepLink = bannerBankAppStore;
    }

    openApp(finalDeepLink, fallbackDeepLink);
  };

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      <FlexContainer
        justify="center"
        alignItems="center"
        gap="4px"
        m="0 0 20px 0"
      >
        <Text display="inline" align="left" size={1} weight={600}>
          {props.finish
            ? t("Zelle.instructions.title")
            : t("Zelle.instructions.titleHowTo")}
          &nbsp;
          <img
            src={ZelleLogo}
            width="57px"
            height="32px"
            style={{ display: "inline", margin: "0 4px -9px 4px" }}
          />
          {props.finish ? "" : ` ?`}
        </Text>
      </FlexContainer>
      <FlexContainer>
        <Text size={0.5} weight={600} align="left" margin="0 0 34px 0">
          {props.finish
            ? t("Zelle.instructions.listTitle")
            : t("Zelle.instructions.listTitleHowTo")}
        </Text>
      </FlexContainer>
      <FlexContainer direction="column" gap="24px" p="0 0 41px 0">
        <FlexContainer gap="24px">
          <StepLogo>
            <IconFont name="device-mobile" color="zelle" size="medium" />
          </StepLogo>
          <FlexContainer direction="column">
            <Text align="left" weight={600} size={0.5} lineHeight="16px">
              {t("Zelle.instructions.step1.title")}
            </Text>
            <Text
              align="left"
              size="default"
              color="grey"
              weight={400}
              lineHeight="16px"
            >
              {t("Zelle.instructions.step1.description")}
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer gap="24px">
          <StepLogo>
            <IconFont name="user-rounded" color="zelle" size="medium" />
          </StepLogo>
          <FlexContainer direction="column">
            <Text align="left" weight={600} size={0.5} lineHeight="16px">
              {t("Zelle.instructions.step3.title")}
            </Text>
            <Text
              align="left"
              size="default"
              color="grey"
              weight={400}
              lineHeight="16px"
            >
              {t("Zelle.instructions.step3.description")}
              <Text
                as="span"
                align="left"
                color="zelle"
                weight={600}
                display="inline"
              >
                &nbsp;{`"${zelleSendolaAccount}".`}
              </Text>
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer gap="24px">
          <StepLogo>
            <IconFont name="currency-dollar" color="zelle" size="medium" />
          </StepLogo>
          <FlexContainer direction="column">
            <Text align="left" weight={600} size={0.5} lineHeight="16px">
              {props.finish
                ? t("Zelle.instructions.step4.title")
                : t("Zelle.instructions.step4.titleHowTo")}
            </Text>
            <Text
              align="left"
              size="default"
              color="grey"
              weight={400}
              lineHeight="16px"
            >
              {props.finish ? (
                <Text as="span" display="inline" color="zelle" weight={600}>
                  {` ${CurrencyService.formatNumberToCurrency(
                    props.amount || 0
                  )}`}{" "}
                  USD
                </Text>
              ) : (
                <Text
                  align="left"
                  size="default"
                  color="grey"
                  weight={400}
                  lineHeight="16px"
                >
                  {t("Zelle.instructions.step4.descriptionHowTo")}
                </Text>
              )}
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer gap="16px">
          <StepLogo>
            <IconFont name="message" color="zelle" size="medium" />
          </StepLogo>
          <FlexContainer direction="column">
            <Text align="left" weight={600} size={0.5} lineHeight="16px">
              {t("Zelle.instructions.step5.title")}
            </Text>
            <Text
              align="left"
              size="default"
              color="grey"
              weight={400}
              lineHeight="16px"
            >
              {props.finish
                ? t("Zelle.instructions.step5.description")
                : t("Zelle.instructions.step5.descriptionHowTo")}
            </Text>
            {props.finish && (
              <FlexContainer gap="16px">
                <Text as="span" color="zelle" weight={600}>
                  {props.reference}
                </Text>
                <Button
                  variant="transparent"
                  padding="0px"
                  onClick={copyTextMtcn}
                >
                  <Text as="span" color="zelle" weight={600}>
                    <IconFont
                      name={copiedText ? "circle-check" : "copy"}
                      color="zelle"
                      size="small"
                    />
                    {` ${t("global.copy")}`}
                  </Text>
                </Button>
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>

        {props.finish && (
          <>
            <FlexContainer direction="column" gap="14px">
              <FlexContainer direction="column" alignItems="center" gap="15px">
                <Button
                  variant="zelle"
                  sizeText="mLarge"
                  sizeButton="large"
                  padding="18px 32px"
                  onClick={handleButtonZelle}
                >
                  <FlexContainer
                    alignItems="center"
                    justify="center"
                    gap="12px"
                  >
                    <Text color="white" size={0.5} weight={500}>
                      {t("Zelle.instructions.submit")}
                    </Text>
                    <img src={ZelleWhiteLogo} width={51} />
                  </FlexContainer>
                </Button>
              </FlexContainer>
              <FlexContainer alignItems="center" gap="16px">
                <hr />
                <Text size={0.5} color="grey" weight={600}>
                  {t("global.or")}
                </Text>
                <hr />
              </FlexContainer>
              <FlexContainer direction="column" alignItems="center" gap="15px">
                <Text size="default" weight={600}>
                  {t("Zelle.instructions.useZelleInBanner")}
                </Text>
                <Button
                  variant="bannerBank"
                  sizeButton="large"
                  padding="18px 26px"
                  onClick={handleButtonBannerBank}
                >
                  <FlexContainer alignItems="center" justify="center">
                    <img src={BannerBankWideLogo} width={114} />
                    <VerticalDivider background="white" height="32px" />
                    <img src={ZelleWhiteLogo} width={51} />
                  </FlexContainer>
                </Button>
              </FlexContainer>
              <FlexContainer justify="center">
                <Link href="https://youtu.be/Ra71FNAjaHk" size={0.5}>
                  <FlexContainer alignItems="center" gap="8px">
                    <IconFont name="play" color="blue" />
                    <Text
                      size={0.5}
                      weight={600}
                      color="blue"
                      textDecoration="underline"
                    >
                      {t("Zelle.instructions.bannerBankVideo")}
                    </Text>
                  </FlexContainer>
                </Link>
              </FlexContainer>
              <FlexContainer justify="center" alignItems="baseline" gap="9px">
                <Text color="grey" size={0.5} weight={400}>
                  {t("Zelle.instructions.helpAdvice")}
                </Text>
                <Link
                  href="#"
                  size={0.5}
                  underline
                  onClick={() => {
                    window.location.href = zelleSupportWA;
                  }}
                >
                  {t("global.contactSupport")}
                </Link>
              </FlexContainer>
            </FlexContainer>
          </>
        )}
      </FlexContainer>
    </Modal>
  );
};
