import { useSelector } from "react-redux";
import { ModalProps } from "../../../Models/ModalInterface";
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import Modal from "../Modal.component";
import { selectorLanguage } from "../../../Redux/Translate";
import Text from "../../Text";
import { FlexContainer } from "../../Flex/Flex.styled";

export const LegalInfoModal = (props: ModalProps) => {
  const { language } = useSelector(selectorLanguage);
  const notesES = [
    "<b>MONTO A ENVIAR</b>. Los límites de envío están sujetos a las regulaciones estatales, documentos proporcionados, y montos de las transacciones realizadas previamente. Todos los usuarios nuevos, en su primera transacción, podrán enviar hasta $999 USD. Para envíos posteriores, si la suma de tus transacciones del mes supera los $999 USD, Sendola realizará verificaciones adicionales sobre tu identidad. Las transacciones posteriores podrán ser de hasta $2,000 USD, siempre y cuando este monto sumado a las transacciones previas no exceda los límites, diarios, semanales o mensuales, según corresponda.",
    "<b>CUENTA DE BANCO</b>. El dinero será depositado en la cuenta bancaria cuyo número de cuenta, número de tarjeta o CLABE interbancaria hayas proporcionado, por lo que es importante que ingreses cuidadosamente la información. Ten en cuenta que, en algunos países, la cuenta de pago del destinatario debe ser una cuenta en moneda local.",
    "<b>RETIRO DE EFECTIVO</b>. Tu beneficiario podrá cobrar el dinero en efectivo, de la transferencia, en cualquiera de las ubicaciones indicadas en la lista de retiro en ventanilla.",
    "<b>TIPO DE CAMBIO</b>. Sendola obtiene ingresos por el intercambio de divisas, excluyendo algunos casos como promociones relacionadas con el tipo de cambio . En transacciones que involucran el intercambio de divisas, es crucial comparar tanto las tarifas de envío, así como los tipos de cambio. Las tarifas, los tipos de cambio y los impuestos pueden variar dependiendo de tu ubicación, el país e institución de destino, el tipo de envío, el método de pago, la velocidad de pago, entre otros factores. Tanto las tarifas como los tipos de cambio pueden modificarse sin notificación previa.",
    "<b>TARIFA DE LA TRANSACCIÓN</b>. Sendola obtiene ingresos por el intercambio de divisas, excluyendo algunos casos como promociones relacionadas con el tipo de cambio . En transacciones que involucran el intercambio de divisas, es crucial comparar tanto las tarifas de envío, así como los tipos de cambio. Las tarifas, los tipos de cambio y los impuestos pueden variar dependiendo de la ubicación, el país e institución de destino, el tipo de envío, el método de pago, la velocidad de pago, entre otros factores. Tanto las tarifas como los tipos de cambio pueden modificarse sin notificación previa.",
    "<b>MÉTODO DE PAGO</b>. Las tarifas asociadas a cada transacción (envío de dinero) dependen de múltiples factores como el país e institución de destino, el tipo de envío, el método de pago, la velocidad de pago, entre otros. Selecciona el método de pago que mejor se adapte a tus necesidades.  Algunos bancos pueden aplicar una tarifa adicional por realizar transferencias bancarias, tanto nacionales como internacionales. Es recomendable consultar el esquema de tarifas de tu banco.",
    "<b>TIEMPO DE ENTREGA</b>. La accesibilidad a los servicios y fondos puede sufrir demoras o estar temporalmente no disponible debido a una variedad de factores, como el tipo de servicio escogido, el monto enviado, el país e institución destino, la disponibilidad de moneda, regulaciones aplicables, asuntos de protección al consumidor, requisitos de identificación, limitaciones de entrega, el horario operativo de las oficinas y las variaciones en los husos horarios. Podrían imponerse restricciones adicionales; para más detalles, revisa nuestros términos y condiciones.",
  ];

  const notesEN = [
    "<b>YOU SEND</b>: Sending limits are subject to state regulations, provided documents, and amounts of previous transactions. All new users can send up to $999 USD in their first transaction. For subsequent transfers, if your total transactions for the month exceed $999 USD, Sendola will conduct additional identity verification. Future transactions may be up to $2,000 USD, as long as the total amount does not exceed daily, weekly, or monthly limits.",
    "<b>DIRECT TO BANK</b>: The money will be deposited into the bank account whose account number, card number, or interbank CLABE you have provided, so it is important to enter the information carefully. Note that, in some countries, the recipient's account must be in the local currency.",
    "<b>CASH PICKUP</b>: Your beneficiary can withdraw the transfer funds in cash at any of the locations listed for over-the-counter pickup.",
    "<b>EXCHANGE RATE</b>: Sendola earns revenue from currency exchange, excluding some cases such as exchange rate-related promotions. In transactions involving currency exchange, it is crucial to compare both sending fees and exchange rates. Fees, exchange rates, and taxes may vary depending on your location, destination country and institution, transfer type, payment method, payment speed, among other factors. Both fees and exchange rates may change without prior notice.",
    "<b>TRANSACTION FEE</b>: Sendola earns revenue from currency exchange, excluding some cases such as exchange rate-related promotions. In transactions involving currency exchange, it is crucial to compare both sending fees and exchange rates. Fees, exchange rates, and taxes may vary depending on location, destination country and institution, transfer type, payment method, payment speed, among other factors. Both fees and exchange rates may change without prior notice.",
    "<b>PAYMENT METHOD FEE</b>: Fees associated with each transaction (money transfer) depend on various factors such as the destination country and institution, transfer type, payment method, payment speed, among others. Choose the payment method that best suits your needs. Some banks may apply an additional fee for bank transfers, both domestic and international. It is recommended to check your bank's fee schedule.",
    "<b>DELIVERY TIME</b>: Access to services and funds may be delayed or temporarily unavailable due to various factors, such as the type of service selected, the amount sent, the destination country and institution, currency availability, applicable regulations, consumer protection issues, identification requirements, delivery limitations, operating hours, and time zone differences. Additional restrictions may apply; for more details, review our terms and conditions.",
  ];

  const notes = language === "en" ? notesEN : notesES;

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      {notes.map((note, key) => (
        <FlexContainer key={key} alignItems="start" gap="16px">
          <Text align="left" weight={600} size={2} margin="0 0 16px 0">
            {key + 1}.
          </Text>
          <Text align="left" margin="0 0 16px 0">
            <span dangerouslySetInnerHTML={{ __html: note }}></span>
          </Text>
        </FlexContainer>
      ))}
    </Modal>
  );
};
