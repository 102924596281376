import { useDispatch, useSelector } from "react-redux";
import { getFeatures } from "../Modules/Common/application/getFeatures";
import { FeatureType } from "../Modules/Common/domain/Feature";
import { createApiFeatureRepository } from "../Modules/Common/infraestructure/ApiFeatureRepository";
import { AppDispatch } from "../Redux/Store";
import {
  FeaturesSelector,
  FeaturesSlice,
} from "../Redux/Features/Features.slice";
import isEmpty from "lodash.isempty";
import { useCallback, useEffect, useMemo } from "react";
import { GetUserFeatureAction } from "../Redux/UserFeature/UserFeature.actions";
import { GET_USER_FEATURE_FULFILLED } from "../Constants/UserFeature";
import { userIdSelector } from "../Redux/User/User.slice";
import {
  NEW_QUOTER,
  PAYPAL_PAYMENT_METHOD,
  REWARDS_FEATURE,
  REWARDS_POPUP_FEATURE,
  SQUARE_PAYMENT_METHOD,
} from "../Constants/Features";
import {
  userFeatureSelector,
  UserFeatureSlice,
} from "../Redux/UserFeature/UserFeature.slice";
import { UserFeature, UserFeaturesResponseData } from "../Models/UserFeature";

export const useFeature = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { appFeatures } = useSelector(FeaturesSelector);
  const userFeatures = useSelector(userFeatureSelector).data;
  const { loading } = useSelector(userFeatureSelector);
  const userIdRedux = useSelector(userIdSelector);
  const newQuoterFeature = useMemo(
    () =>
      userFeatures?.find((feat) => feat.featureCode === NEW_QUOTER)?.isActive,
    [userFeatures]
  );
  const squarePaymentMethodFeature = useMemo(
    () =>
      userFeatures?.find((feat) => feat.featureCode === SQUARE_PAYMENT_METHOD)
        ?.isActive,
    [userFeatures]
  );
  const paypalPaymentMethodFeature = useMemo(
    () =>
      userFeatures?.find((feat) => feat.featureCode === PAYPAL_PAYMENT_METHOD)
        ?.isActive,
    [userFeatures]
  );
  const rewardsFeature = useMemo(
    () =>
      userFeatures?.find((feat) => feat.featureCode === REWARDS_FEATURE)
        ?.isActive,
    [userFeatures]
  );
  const rewardsPopupFeature = useMemo(
    () =>
      userFeatures?.find((feat) => feat.featureCode === REWARDS_POPUP_FEATURE)
        ?.isActive,
    [userFeatures]
  );

  const isLoading = loading;

  const userId = useMemo(() => userIdRedux || "", [userIdRedux]);

  const getAppFeatures = useCallback(
    async (opts: { type?: FeatureType; reload?: boolean }) => {
      let features = appFeatures;
      if (
        opts.reload ||
        isEmpty(appFeatures) ||
        isEmpty(appFeatures.filter((feat) => feat.type === opts.type))
      ) {
        features = [
          ...appFeatures,
          ...(await getFeatures(createApiFeatureRepository())()),
        ];
        dispatch(FeaturesSlice.actions.setAppFeatures(features));
      }

      if (opts.type) {
        features = features.filter((feat) => feat.type === opts.type);
      }

      return features;
    },
    [appFeatures]
  );

  const getUserFeature = async (
    userId: string,
    featureCode: string,
    setUserFeature?: (active: boolean) => void
  ) => {
    const request = {
      userId: userId,
      featureCode: featureCode,
    };
    const response = await dispatch(GetUserFeatureAction(request));

    if (response?.type === GET_USER_FEATURE_FULFILLED) {
      setUserFeature &&
        setUserFeature(
          (response?.payload as UserFeaturesResponseData)?.isActive
        );
      return (response?.payload as UserFeaturesResponseData)?.isActive;
    }

    return false;
  };

  const getAllUserFeatures = async () => {
    const features = [];
    let newQuoterIsActive = newQuoterFeature;
    let squarePaymentMethodIsActive = squarePaymentMethodFeature;
    let paypalPaymentMethodIsActive = paypalPaymentMethodFeature;
    let rewardsIsActive = rewardsFeature;
    let rewardsPopupIsActive = rewardsPopupFeature;
    if (newQuoterFeature === undefined) {
      newQuoterIsActive = await getUserFeature(userId, NEW_QUOTER);
      features.push({
        featureCode: NEW_QUOTER,
        isActive: newQuoterIsActive,
      } as UserFeature);
    }
    if (squarePaymentMethodFeature === undefined) {
      squarePaymentMethodIsActive = await getUserFeature(
        userId,
        SQUARE_PAYMENT_METHOD
      );
      features.push({
        featureCode: SQUARE_PAYMENT_METHOD,
        isActive: squarePaymentMethodIsActive,
      } as UserFeature);
    }
    if (paypalPaymentMethodFeature === undefined) {
      paypalPaymentMethodIsActive = await getUserFeature(
        userId,
        PAYPAL_PAYMENT_METHOD
      );
      features.push({
        featureCode: PAYPAL_PAYMENT_METHOD,
        isActive: paypalPaymentMethodIsActive,
      } as UserFeature);
    }
    if (rewardsFeature === undefined) {
      rewardsIsActive = await getUserFeature(userId, REWARDS_FEATURE);
      features.push({
        featureCode: REWARDS_FEATURE,
        isActive: rewardsIsActive,
      } as UserFeature);
    }
    if (rewardsPopupFeature === undefined) {
      rewardsPopupIsActive = await getUserFeature(
        userId,
        REWARDS_POPUP_FEATURE
      );
      features.push({
        featureCode: REWARDS_POPUP_FEATURE,
        isActive: rewardsPopupIsActive,
      } as UserFeature);
    }

    if (!isEmpty(features)) {
      dispatch(UserFeatureSlice.actions.setFeatures(features));
    }
  };

  useEffect(() => {
    if (userId) {
      getAllUserFeatures();
    }
  }, [userId]);

  return {
    appFeatures,
    newQuoterFeature,
    squarePaymentMethodFeature,
    paypalPaymentMethodFeature,
    rewardsFeature,
    rewardsPopupFeature,
    isLoading,
    getAppFeatures,
    getUserFeature,
  };
};
