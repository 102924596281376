import theme from "styled-theming";

import { theme as config } from "./Theme";

const iconWihGradient = theme("currentTheme", {
  default: `linear-gradient(
    270deg,
    ${config.Primary_03} 0%,
    ${config.Primary_01} 100%
  );
`,
  martori: `linear-gradient(
    270deg,
    ${config.MartoriGradient_01} 0%,
    ${config.MartoriGradient_02} 100%
  );
`,
});

const iconShapeWihGradient = theme("currentTheme", {
  default: `linear-gradient(
    180deg,
    ${config.Primary_03} 0%,
    ${config.Primary_01} 100%
  );
`,
  martori: `linear-gradient(
    180deg,
    ${config.MartoriGradient_01} 0%,
    ${config.MartoriGradient_02} 100%
  );
`,
});

const iconWithGradientSecondary = theme("currentTheme", {
  default: `linear-gradient(
    8.83deg,
    ${config.Primary_01_800} 0.97%, 
    ${config.Primary_01} 112.47%)`,
  martori: `linear-gradient(
    270deg,
    ${config.MartoriGradient_01} 0%,
    ${config.MartoriGradient_02} 100%
̃  )`,
});

const iconShapeFlat = theme("currentTheme", {
  default: config.Primary_01,
  martori: config.MartoriPrimary_01,
});

const iconColors = theme.variants("currentTheme", "color", {
  white: {
    default: config.Background_01,
    martori: config.MartoriWhite,
  },
  black: {
    default: config.Background_02,
    martori: config.Background_02,
  },
  gray: {
    default: config.Content_UX_02,
    martori: config.Content_UX_02,
  },
  disabled: {
    default: config.Content_State_UX_Disabled,
    martori: config.Content_State_UX_Disabled,
  },
  black_highlight: {
    default: config.Content_UX_01,
    martori: config.Content_UX_01,
  },
  primary: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  primaryStrong: {
    default: config.Primary_01_800,
    martori: config.MartoriPrimary_900,
  },
  primaryHighlight: {
    default: config.Primary_01_500,
    martori: config.MartoriPrimary_01,
  },
  primaryLight: {
    default: config.Primary_01_50,
    martori: config.Primary_01_50,
  },
  zelle: {
    default: config.Zelle,
    martori: config.Zelle,
  },
  secondary: {
    default: config.Secondary_01,
    martory: config.MartoriGradient_01,
  },
  warning: {
    default: config.Primary_08,
    martori: config.Primary_08,
  },
  error: {
    default: config.Error,
    martori: config.Error,
  },
  transparent: {
    default: config.Transparent,
    martori: config.Transparent,
  },
  blue: {
    default: config.BannerBank,
    martori: config.BannerBank,
  },
});

export {
  iconWihGradient,
  iconColors,
  iconShapeFlat,
  iconShapeWihGradient,
  iconWithGradientSecondary,
};
