import { useTranslation } from "react-i18next";
import { FlexContainer } from "../Flex/Flex.styled";
import IconFont from "../IconFont";
import Text from "../Text";
import { CouponBarContainer } from "./CouponBar.styled";

interface CouponBarProps {
  couponCode: string;
}

export const CouponBar = ({ couponCode }: CouponBarProps) => {
  const [tSendMoney] = useTranslation("sendMoney");

  return (
    <CouponBarContainer>
      <IconFont name="discount-percent-flame" color="primaryLight" />
      <FlexContainer w="auto">
        <Text color="white" align="left" weight={600}>
          {`${tSendMoney("Coupons.CouponApplied")} ${couponCode}`}
        </Text>
      </FlexContainer>
    </CouponBarContainer>
  );
};
