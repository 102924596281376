import { css } from "styled-components";
import theme from "styled-theming";

import { theme as config } from "./Theme";

const headerFontColor = theme("currentTheme", {
  default: `rgba(${config.RGBA_07})`,
  martori: `rgba(${config.RGBA_07})`,
});

const headerBackgroundColor = theme("currentTheme", {
  default: `linear-gradient(90deg, ${config.Neutral_900} 61%, ${config.Primary_01_700} 100%);`,
  martori: `linear-gradient(90deg, ${config.Neutral_900} 61%, ${config.MartoriPrimary_900} 100%);`,
});

const headerLogo = theme("currentTheme", {
  default: css`
    height: 41px;
    width: auto;
  `,
  martori: css`
    height: 35px;
    width: 95px;
  `,
});

const footerBackgroundColor = theme("currentTheme", {
  default: config.Neutral_900,
  martori: config.MartoriPrimary_900,
});

const footerTitle = theme("currentTheme", {
  default: config.Primary_04,
  martori: config.MartoriPrimary_900,
});

const footerRibbonBackgroundColor = theme("currentTheme", {
  default: `linear-gradient(
    270deg,
    ${config.Primary_03} 0%,
    ${config.Primary_01} 100%
  );
`,
  martori: `linear-gradient(
    225deg,
    ${config.MartoriGradient_01} 0%,
    ${config.MartoriGradient_02} 100%
  );
`,
});

const footerLogo = theme("currentTheme", {
  default: css`
    height: 85px;
    width: auto;
  `,
  martori: css`
    height: 35px;
    width: 95px;
  `,
});

const loaderColor = theme("currentTheme", {
  default: config.Primary_01,
  martori: config.MartoriPrimary_01,
});

const cardBorderGradient = theme("currentTheme", {
  default: `linear-gradient(
    90deg,
    ${config.Primary_01} 0%,
    ${config.Primary_Blue} 100%
  )`,
  martori: `linear-gradient(
    90deg,
    ${config.MartoriGradient_01} 0%,
    ${config.MartoriGradient_02} 100%
  )`,
});

const cardBorder = theme.variants("currentTheme", "borderColor", {
  white: {
    default: config.Background_01,
    martori: config.MartoriWhite,
  },
  black: {
    default: config.Background_02,
    martori: config.Background_02,
  },
  gray: {
    default: config.Primary_18,
    martori: config.Primary_18,
  },
  disabled: {
    default: config.Content_State_UX_Disabled,
    martori: config.Content_State_UX_Disabled,
  },
  black_highlight: {
    default: config.Content_UX_01,
    martori: config.Content_UX_01,
  },
  primary: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  primaryHighlight: {
    default: config.Primary_01_500,
    martori: config.MartoriPrimary_01,
  },
  secondary: {
    default: config.Secondary_01,
    martory: config.MartoriGradient_01,
  },
  warning: {
    default: config.Primary_08,
    martori: config.Primary_08,
  },
  error: {
    default: config.Error,
    martori: config.Error,
  },
  transparent: {
    default: config.Transparent,
    martori: config.Transparent,
  },
  muted: {
    default: config.Content_UX_05,
    martori: config.Content_UX_05,
  },
  coppel: {
    default: config.Coppel,
    martori: config.Coppel,
  },
});

const tagBackground = theme("currentTheme", {
  default: config.Primary_01_25,
  martori: config.MartoriPrimary_01,
});

const tagColor = theme("currentTheme", {
  default: config.Primary_01,
  martori: config.White,
});

const modalGradientBackground = theme("currentTheme", {
  default: `linear-gradient(180deg, ${config.Background_06} 90.61%, ${config.Primary_01} 168.89%)`,
  martori: `linear-gradient(180deg, ${config.Background_06} 90.61%, ${config.MartoriPrimary_01} 168.89%)`,
});

const quotedFeeGradientBackground = theme("currentTheme", {
  default: `linear-gradient(8.83deg, ${config.Primary_01_800} 0.97%, ${config.Primary_01} 112.47%);`,
  martori: `linear-gradient(8.83deg, ${config.MartoriGradient_01} 0.97%, ${config.MartoriPrimary_01} 112.47%);`,
});

export {
  footerBackgroundColor,
  footerRibbonBackgroundColor,
  footerTitle,
  footerLogo,
  headerBackgroundColor,
  headerFontColor,
  headerLogo,
  loaderColor,
  cardBorderGradient,
  cardBorder,
  tagBackground,
  tagColor,
  modalGradientBackground,
  quotedFeeGradientBackground,
};
