import { FontStyled } from "./IconFont.styled";

import {
  IconSize,
  IconsFontColors,
  IconsFontType,
} from "../../Models/IconInterface";
import { CSSProperties } from "styled-components";

interface Props {
  name: IconsFontType;
  size?: IconSize;
  color?: IconsFontColors;
  gradient?: boolean;
  weight?: CSSProperties["fontWeight"];
  lineHeight?: CSSProperties["lineHeight"];
  onClick?: () => void;
}

const IconFont = ({ name, size, ...props }: Props) => {
  return (
    <FontStyled
      className={`icon-se-${name}`}
      size={size || "default"}
      {...props}
    />
  );
};

export default IconFont;
