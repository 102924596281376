import { useTranslation } from "react-i18next";
import {
  CountrieWhithoutCP,
  CountrieWhithoutD2B,
  ExchangeRate,
  SenderExchangeRates,
} from "../../Modules/Common/domain/ExchangeRates";
import {
  QuoteOption,
  QuoterProps,
  QuoteSelectedValue,
} from "../../Modules/Quote/domain/QuoteSelected";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectorLanguage } from "../../Redux/Translate";
import { useCountry } from "../../Hooks/useCountry";
import { AppDispatch, State } from "../../Redux/Store";
import { allowedCountries } from "../../Modules/Common/domain/Countries";
import isEmpty from "lodash.isempty";
import { CodeKeys } from "../../Models/ModalInterface";
import { SelectOption } from "../../Models/Select";
import { useDebounce } from "../../Hooks/useDebounce";
import { FlexContainer } from "../Flex/Flex.styled";
import { PaymentMethodSelect } from "./PaymentMethodSelect/PaymentMethodSelect.component";
import {
  ExchangeRateContainer,
  QuoterCardWrapper,
} from "./QuoterCardAdvanced.styled";
import { RecipientsSelect } from "./RecipientsSelect/RecipientsSelect.component";
import { formatNumber } from "../../Utilities/NumberUtils";
import { MoneyInputLight } from "../MoneyInput/MoneyInputLight.component";
import {
  ErrorVelocityLimitsStyled,
  IconVelocityLimits,
  TextVelocityLimits,
} from "../QuoterCard/QuoterCard.styled";
import Icon from "../Icon";
import { DeliveryMethodSelect } from "./DeliveryMethodSelect/DeliveryMethodSelect.component";
import Text from "../Text";
import Button from "../Button";
import { BeneficiarySlice } from "../../Redux/Beneficiary";
import { BeneficiarySelector } from "../../Redux/Beneficiary/Beneficiary.slice";
import { SearchSelect } from "../SearchSelect/SearchSelect.component";
import { quoterSelectStyles } from "../QuoterCard/QuoterSelectTheme";
import { Currency } from "../../Modules/Common/domain/Currency";
import { BeneficiaryRecipient } from "../../Modules/Beneficiary/domain/Beneficiary";
import { PersonsKYCSelector } from "../../Redux/PersonsKYC";
import { KYC_APPROVED } from "../../Constants/KYCStatus";
import { useLocation } from "react-router-dom";
import {
  PaymentMethod,
  PlaidAccount,
} from "../../Modules/Common/domain/PaymentMethods";
import { PlatformAppURL } from "../../Constants/ExternalServices";
import IconFont from "../IconFont";
import { Superscript } from "../Text/Superscript.component";
import Link from "../Link";
import { useModal } from "../../Hooks/useModal";
import { getUrl } from "../../Utilities/Redirect";
import { CouponBar } from "../CouponBar/CouponBar.component";

export const QuoterCardAdvanced = ({
  deliveryMethodsSelect = [],
  sendToOptionsSelect = [],
  value,
  maxAmount,
  minAmount,
  showVelocityLimit,
  codesMessages = [],
  deliveryMethodCurrency,
  proofOfLifeNeeded,
  couponToApply,
  handleSubmit,
  onChange,
}: QuoterProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [tSendMoney] = useTranslation("sendMoney");
  const [t] = useTranslation("global");
  const [tModals] = useTranslation("modals");
  const lang = useSelector(selectorLanguage);
  const { countries } = useCountry();
  const location = useLocation();
  const { modal: legalNotesModal, showModal: showLegalNotesModal } = useModal();

  const [amount, setAmount] = useState<number | string>(value?.amount || 500);
  const [amountInputValue, setAmountInputValue] = useState<number | string>(
    amount
  );
  const [currency, setCurrency] = useState<string | undefined>(
    value?.currency ||
      (countries.find((c) => c.countryCode === "MEX")
        ? Currency.MXN
        : countries[0]?.defaultCurrency)
  );
  const [country, setCountry] = useState<string | undefined>(
    value?.country ||
      (countries.find((c) => c.countryCode === "MEX")
        ? "MEX"
        : countries[0]?.countryCode)
  );
  const [deliveryMethodsList, setDeliveryMethodsList] = useState<QuoteOption[]>(
    []
  );
  const [deliveryMethod, setDeliveryMethod] = useState<
    QuoteSelectedValue["deliveryMethod"]
  >(deliveryMethodCurrency);
  const [sendTo, setSendTo] = useState<string | null>(value?.sendTo || null);
  const [balanceNotValid, setBalanceNotValid] = useState(false);

  const {
    currentQuotation,
    currentDeliveryMethodQuote,
    countryDestination,
    currentPaymentDestination,
    currentPaymentMethod,
    paymentMethodSelected,
  } = useSelector((state: State) => state.quotation.data);
  const {
    data: { beneficiarySelected },
  } = useSelector(BeneficiarySelector);
  const personData = useSelector(PersonsKYCSelector).data;
  const person = personData?.personVeriff;
  const kycLevel2 = person?.kycLevelStatus?.find((kyc) => kyc.level === 2);

  const maxExceeded = Boolean(maxAmount && Number(amount) > maxAmount);
  const minNotReached = Boolean(minAmount && Number(amount) < minAmount);
  const amountNotValid =
    Boolean(value?.amount) && (maxExceeded || minNotReached);

  const cantSubmit =
    !currentQuotation ||
    !currentDeliveryMethodQuote ||
    !countryDestination ||
    !currentPaymentDestination ||
    !allowedCountries.includes(countryDestination) ||
    amountNotValid ||
    (!isEmpty(codesMessages) && showVelocityLimit) ||
    !paymentMethodSelected ||
    !currentPaymentMethod ||
    !beneficiarySelected ||
    (Number(amount) > 999 && personData?.personVeriff?.status !== "approved");

  const messagesVelocity: CodeKeys = useMemo(
    () => ({
      VL001: tModals("velocityLimits.messagetxnDaily"),
      VL002: tModals("velocityLimits.messageTxnMonthly"),
      VL003: tModals("velocityLimits.messageAmountDaily"),
      VL004: tModals("velocityLimits.messageAmountMonthly"),
      VL007: tModals("velocityLimits.messageMinAmountDestination"),
      VL008: tModals("velocityLimits.messageMaxAmountDestination"),
      VL009: tModals("velocityLimits.messageAmountDestinationDaily"),
      VL010: tModals("velocityLimits.messageAmountDestinationMonthly"),
      VL013: tModals("velocityLimits.messageTxs"),
      VL014: tModals("velocityLimits.messageAmountTxs"),
      VL015: tModals("velocityLimits.messageCountrySubdivisionDaily", {
        limit: codesMessages?.find((item) => item.code === "VL015")?.value,
      }),
      VL016: tModals("velocityLimits.messageCountrySubdivisionMonthly", {
        limit: codesMessages?.find((item) => item.code === "VL016")?.value,
      }),
      VL017: tModals("velocityLimits.messageBasicKYCLimitDaily", {
        limit: codesMessages?.find((item) => item.code === "VL017")?.value,
      }),
      VL018: tModals("velocityLimits.messageBasicKYCLimitMonthly", {
        limit: codesMessages?.find((item) => item.code === "VL018")?.value,
      }),
      VL019: tModals("velocityLimits.messageBasicKYCDeclined"),
      VB001: tModals("velocityLimits.messageNegativeBalanceQuoter"),
    }),
    [codesMessages, lang]
  );

  const totalCost =
    person?.applyFeePromotion || couponToApply
      ? Number(currentPaymentMethod?.totalCost || "0") -
        Number(value?.currentQuote?.fee || "0")
      : currentPaymentMethod?.totalCost || 0;

  async function handleDeliveryMethod(optionSelected: SelectOption) {
    if (optionSelected?.value !== deliveryMethod) {
      setSendTo(null);
      setDeliveryMethod(
        optionSelected.value as QuoteSelectedValue["deliveryMethod"]
      );
    }
  }

  async function handleCurrencyChange(exchangeRate: ExchangeRate) {
    setSendTo(null);
    dispatch(BeneficiarySlice.actions.setBeneficiarySelected(undefined));
    setCurrency(exchangeRate?.currency);
    setCountry(exchangeRate?.countryCode);
  }

  function handleBankInstitution(optionSelected: SelectOption) {
    setSendTo(optionSelected.value);
  }

  function handleRecipientChange(recipient?: BeneficiaryRecipient | null) {
    if (deliveryMethod === "D2B" && recipient?.accountSelected?.destinationId) {
      dispatch(BeneficiarySlice.actions.setBeneficiarySelected(recipient));
      setSendTo(recipient.accountSelected.destinationId);
    } else if (deliveryMethod === "CPU") {
      dispatch(BeneficiarySlice.actions.setBeneficiarySelected(recipient));
    } else if (!recipient) {
      dispatch(BeneficiarySlice.actions.resetState());
      setSendTo(null);
    }
  }

  const setValues = () => {
    if (value?.amount) {
      setAmount(value.amount);
      setAmountInputValue(value.amount);
    }
    value?.currency && setCurrency(value.currency);
    value?.country && setCountry(value.country);
    getDeliveryMethod();
    setSendTo(value?.sendTo || null);
  };

  const resetValues = () => {
    setAmount(500);
    setCurrency(countries[1]?.currency);
    setCountry(countries[1]?.countryCode);
    setDeliveryMethod("D2B");
    getDeliveryMethod();
    setSendTo(null);
  };

  const triggerOnChange = useDebounce(() => {
    const newValue = {
      amount: Number(amount || "0"),
      currency,
      country,
      deliveryMethod,
      sendTo,
    };
    onChange && onChange(newValue, cantSubmit);
  }, 500);

  useEffect(() => {
    if (
      paymentMethodSelected &&
      sendTo &&
      !sendToOptionsSelect.find(
        (sendToOption) => sendToOption.value === sendTo
      ) &&
      location.state?.previous !== "createRecipient"
    ) {
      dispatch(BeneficiarySlice.actions.resetState());
      setSendTo(null);
    }
  }, [paymentMethodSelected, sendToOptionsSelect]);

  useEffect(() => {
    triggerOnChange();
  }, [
    amount,
    currency,
    country,
    deliveryMethod,
    sendTo,
    paymentMethodSelected?.id,
  ]);

  useEffect(() => {
    if (
      !isEmpty(value) &&
      (value?.amount?.toString() !== amount?.toString() ||
        value?.currency !== currency ||
        value?.sendTo !== sendTo ||
        value?.deliveryMethod !== deliveryMethod ||
        value?.country !== country)
    ) {
      setValues();
    }
  }, [value?.amount, value?.currency, value?.sendTo, value?.deliveryMethod]);

  useEffect(() => {
    if (lang) {
      getDeliveryMethod();
    }
  }, [CountrieWhithoutD2B, CountrieWhithoutCP, country, lang]);

  useEffect(() => {
    if (
      !isEmpty(countries) &&
      (!country || !countries.find((c) => c.countryCode === country))
    ) {
      setCurrency(countries[0]?.currency);
      setCountry(countries[0]?.countryCode);
    }
  }, [countries]);

  useEffect(() => {
    try {
      const paymentMethod = structuredClone(paymentMethodSelected);
      const paymentMethodIsPlaidAccount =
        paymentMethod?.paymentSource === "Sendola Plaid";
      const paymentMethodIsSendolaPay =
        paymentMethod?.paymentSource === "Sendola Card";
      if (
        paymentMethod &&
        currentPaymentMethod &&
        (paymentMethodIsPlaidAccount || paymentMethodIsSendolaPay) &&
        !amountNotValid
      ) {
        const plaidBalanceIsLessThanTotal =
          paymentMethodIsPlaidAccount &&
          (paymentMethod as PlaidAccount).available < totalCost;

        const sendolaBalanceIsLessThanTotal =
          paymentMethodIsSendolaPay &&
          (paymentMethod as PaymentMethod).availableBalance < totalCost;

        if (plaidBalanceIsLessThanTotal || sendolaBalanceIsLessThanTotal) {
          if (!codesMessages.find((error) => error.code === "VB001")) {
            codesMessages?.push({
              code: "VB001",
              value: (
                (paymentMethod as PlaidAccount).available ||
                (paymentMethod as PaymentMethod).availableBalance
              ).toString(),
              msg: tModals("velocityLimits.messageNegativeBalance"),
            });
            setBalanceNotValid(true);
          }
        } else {
          setBalanceNotValid(false);
          const idx = codesMessages.findIndex(
            (error) => error.code === "VB001"
          );
          if (idx !== -1) {
            codesMessages?.splice(idx, 1);
          }
        }
      } else {
        setBalanceNotValid(false);
        const idx = codesMessages.findIndex((error) => error.code === "VB001");
        if (idx !== -1) {
          codesMessages?.splice(idx, 1);
        }
      }
    } catch (error) {
      setBalanceNotValid(false);
      const idx = codesMessages.findIndex((error) => error.code === "VB001");
      if (idx !== -1) {
        codesMessages?.splice(idx, 1);
      }
    }
  }, [paymentMethodSelected, currentPaymentMethod, amountNotValid]);

  const getDeliveryMethod = () => {
    if (country) {
      if (
        CountrieWhithoutCP.includes(country as string) ||
        personData?.personVeriff?.countrySubdivision === "PR"
      ) {
        const delivery = [deliveryMethodsSelect[0]];
        setDeliveryMethodsList(delivery);
        setDeliveryMethod("D2B");
      } else if (CountrieWhithoutD2B.includes(country as string)) {
        const delivery = [deliveryMethodsSelect[1]];
        setDeliveryMethodsList(delivery);
        setDeliveryMethod("CPU");
      } else {
        setDeliveryMethodsList(deliveryMethodsSelect);
      }
    }
  };

  return (
    <QuoterCardWrapper>
      {showVelocityLimit && (
        <FlexContainer
          direction="column"
          gap="16px"
          mw="26.5625rem"
          flex="1 1 auto"
          p="5px 16px"
        >
          <ErrorVelocityLimitsStyled>
            <IconVelocityLimits>
              <Icon icon="alertTriangle" color="error" size="large" />
            </IconVelocityLimits>
            <TextVelocityLimits>
              {codesMessages &&
                codesMessages.map((item, index) => (
                  <label key={index}>
                    {messagesVelocity[item.code] || t("global.unknownError")}
                  </label>
                ))}
            </TextVelocityLimits>
          </ErrorVelocityLimitsStyled>
        </FlexContainer>
      )}
      <FlexContainer
        direction="column"
        gap="16px"
        mw="26.5625rem"
        flex="1 1 auto"
        p="5px 16px 24px 16px"
      >
        <RecipientsSelect
          country={country}
          deliveryMethod={deliveryMethod}
          sendToOptions={sendToOptionsSelect}
          onChange={handleRecipientChange}
        />
        <FlexContainer alignItems="center">
          <MoneyInputLight
            label={tSendMoney("Send")}
            labelSuperScript="1"
            labelInfo={tSendMoney("KnowSendingLimits")}
            amount={amountInputValue.toString()}
            data={SenderExchangeRates}
            errorLabel={
              maxExceeded
                ? tSendMoney("errors.maxAmount", {
                    maxAmount: formatNumber(maxAmount || 0),
                  })
                : minNotReached
                ? tSendMoney("errors.minAmount", {
                    minAmount: formatNumber(minAmount || 0),
                  })
                : undefined
            }
            onLabelInfoClick={() => {
              showLegalNotesModal({
                modalType: "legalInfoModal",
              });
            }}
            handleInputChange={(e) => {
              setAmountInputValue(e.target.value);
            }}
            handleInputBlur={() => {
              setAmount(amountInputValue);
            }}
            maxLength={6}
          />
        </FlexContainer>
        {amountNotValid && (
          <ErrorVelocityLimitsStyled>
            <IconVelocityLimits>
              <Icon icon="alertTriangle" color="error" size="large" />
            </IconVelocityLimits>
            <TextVelocityLimits>
              {maxExceeded ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: tSendMoney("errors.maxAmount", {
                      maxAmount: formatNumber(maxAmount || 0),
                    }),
                  }}
                />
              ) : minNotReached ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: tSendMoney("errors.minAmount", {
                      minAmount: formatNumber(minAmount || 0),
                    }),
                  }}
                />
              ) : undefined}
            </TextVelocityLimits>
          </ErrorVelocityLimitsStyled>
        )}
        <hr />
        <FlexContainer>
          <MoneyInputLight
            label={tSendMoney("Recipient")}
            currency={currency}
            country={country}
            amount={value?.currentQuote?.amountToReceive?.toString() || "0"}
            data={countries}
            handleInputChange={() => false}
            handleCurrencyChange={handleCurrencyChange}
            disabled
          ></MoneyInputLight>
        </FlexContainer>
        {(Number(amount) > 999 ||
          codesMessages.some((item) => item.code === "VL018")) &&
          (!kycLevel2?.status || kycLevel2.status !== KYC_APPROVED) && (
            <ErrorVelocityLimitsStyled>
              <IconVelocityLimits>
                <Icon icon="alertTriangle" color="error" size="large" />
              </IconVelocityLimits>
              <TextVelocityLimits>
                {proofOfLifeNeeded ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: tModals(
                        "velocityLimits.messageKYC1Resubmission",
                        {
                          href: getUrl(PlatformAppURL, { proofOfLife: true }),
                        }
                      ),
                    }}
                  />
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: tModals("velocityLimits.messageKYC1Limit"),
                    }}
                  />
                )}
              </TextVelocityLimits>
            </ErrorVelocityLimitsStyled>
          )}
        {value?.currentQuote?.fx && (
          <FlexContainer direction="column">
            <FlexContainer
              alignItems="baseline"
              justify="space-between"
              p="2px 8px 4px 8px"
              as={ExchangeRateContainer}
            >
              <Text
                size={0.5}
                lineHeight="21px"
                align="left"
                weight={600}
                margin={0}
              >
                {tSendMoney("ExchangeRate")}
                <Superscript note="4" />:
              </Text>
              <Text
                size={0.5}
                lineHeight="21px"
                align="right"
                weight={600}
                margin={0}
              >
                $1.00 USD = ${value.currentQuote.fx.toFixed(4)} {currency}
              </Text>
            </FlexContainer>
            <Link
              href="#"
              size={0.1}
              align="left"
              color="grey"
              underline
              onClick={() => {
                showLegalNotesModal({
                  modalType: "legalInfoModal",
                });
              }}
            >
              {tSendMoney("ExchangeRateDisclosure")}
            </Link>
          </FlexContainer>
        )}
        <DeliveryMethodSelect
          options={deliveryMethodsList}
          value={deliveryMethod}
          onChange={handleDeliveryMethod}
        />
        {deliveryMethod === "CPU" && (
          <SearchSelect
            labelProps={{ color: "solid_light", size: 0 }}
            label={tSendMoney("PickupLocation")}
            options={sendToOptionsSelect}
            name="country"
            onChange={handleBankInstitution}
            value={
              sendTo
                ? sendToOptionsSelect.find((c) => c.value === sendTo)
                : null
            }
            customStyles={quoterSelectStyles}
            showAvatar
          />
        )}
        <PaymentMethodSelect />
        {person?.applyFeePromotion ? (
          <CouponBar couponCode="WELCOME-0TRANSFERFEE" />
        ) : (
          couponToApply && (
            <CouponBar couponCode={couponToApply.code || "REF-FRIENDS"} />
          )
        )}
        <FlexContainer direction="column">
          <FlexContainer alignItems="baseline" justify="space-between">
            <Text
              size={0.5}
              lineHeight="21px"
              weight={500}
              color="grey"
              margin={0}
            >
              {tSendMoney("TransferFees")}
              <Superscript note="5" />:
            </Text>
            {person?.applyFeePromotion || couponToApply ? (
              <FlexContainer w="max-content" alignItems="center" gap="6px">
                <Text
                  size={0.5}
                  lineHeight="21px"
                  weight={500}
                  color="solid_2"
                  textDecoration="line-through"
                >
                  $
                  {formatNumber(value?.currentQuote?.fee || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </Text>
                <Text size={0.5} lineHeight="21px" weight={500}>
                  $
                  {formatNumber(0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </Text>
              </FlexContainer>
            ) : (
              <Text size={0.5} lineHeight="21px" weight={500}>
                $
                {formatNumber(value?.currentQuote?.fee || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                USD
              </Text>
            )}
          </FlexContainer>
          {currentPaymentMethod && (
            <FlexContainer alignItems="baseline" justify="space-between">
              <Text
                size={0.5}
                lineHeight="21px"
                weight={500}
                color="grey"
                margin={0}
              >
                {tSendMoney("PaymentMethodFee")}
                <Superscript note="6" />:
              </Text>
              <Text size={0.5} lineHeight="21px" weight={500} margin={0}>
                $
                {formatNumber(currentPaymentMethod.paymentMethodFee || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                USD
              </Text>
            </FlexContainer>
          )}
        </FlexContainer>
        <hr style={{ margin: "-7px 0" }} />
        <FlexContainer justify="space-between">
          <Text
            size={0.5}
            lineHeight="21px"
            weight={700}
            color="grey"
            margin={0}
          >
            {tSendMoney("TotalPayment")}:
          </Text>
          <Text
            size={0.5}
            lineHeight="21px"
            weight={500}
            color="magenta"
            margin={0}
          >
            $
            {formatNumber(totalCost, {
              minimumFractionDigits: 2,
            })}{" "}
            USD
          </Text>
        </FlexContainer>
        {currentPaymentMethod?.paymentOrigin === "PayPal" && (
          <Text color="success">
            <span
              dangerouslySetInnerHTML={{
                __html: tSendMoney("paymentMethods.paypal.disclaimer"),
              }}
            ></span>
          </Text>
        )}
        {balanceNotValid && (
          <FlexContainer gap="5px" flex="1 1 auto" alignItems="center">
            <IconVelocityLimits>
              <Icon icon="alertTriangle" color="error" size="medium" />
            </IconVelocityLimits>
            <Text size={0.5} color="error" align="left" weight={500} margin={0}>
              {messagesVelocity["VB001"]}
            </Text>
          </FlexContainer>
        )}
        <FlexContainer gap="4px">
          <IconFont name="info" color="gray" />
          <Text align="left" color="grey">
            {tSendMoney("QuoteDisclosure")}&nbsp;
            <Link
              href="#"
              align="left"
              color="grey"
              size="default"
              underline
              onClick={() => {
                showLegalNotesModal({
                  modalType: "legalInfoModal",
                });
              }}
            >
              {tSendMoney("ViewLegalNotes")}
            </Link>
          </Text>
        </FlexContainer>
        <FlexContainer>
          <Button
            variant="primary"
            sizeButton="full"
            text={tSendMoney("Next")}
            sizeText="medium"
            iconButton="arrowRight"
            colorIcon="white"
            sizeIcon="xlarge"
            padding="8px"
            disabled={cantSubmit}
            onClick={handleSubmit}
          />
        </FlexContainer>
      </FlexContainer>
      {legalNotesModal}
    </QuoterCardWrapper>
  );
};
