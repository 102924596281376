import { useTranslation } from "react-i18next";
import Button from "../Button";
import Card from "../Card/Card.component";
import { Column } from "../Grid";
import MoneyInput from "../MoneyInput";
import {
  ButtonSectionCard,
  ButtonSectionMobile,
  CardMoney,
  ErrorVelocityLimitsStyled,
  ExchangeRate,
  IconVelocityLimits,
  PaymentAmountAmount,
  PaymentAmountLabel,
  PaymentAmountStyled,
  PaymentAmountSubLabel,
  SelectBankTitle,
  TextVelocityLimits,
  TransferFeeAmount,
  TransferFeeTitle,
  TransferFeesStyled,
} from "./QuoterCard.styled";
import { SelectOption } from "../../Models/Select";
import { useEffect, useMemo, useState } from "react";
import Title from "../Title";
import {
  CountrieWhithoutCP,
  CountrieWhithoutD2B,
  SenderExchangeRates,
  ExchangeRate as tExchangeRate,
} from "../../Modules/Common/domain/ExchangeRates";
import { useDebounce } from "../../Hooks/useDebounce";
import { formatNumber } from "../../Utilities/NumberUtils";
import { State } from "../../Redux/Store";
import { useSelector } from "react-redux";
import { SearchSelect } from "../SearchSelect/SearchSelect.component";
import { isEmpty } from "lodash";
import {
  QuoteOption,
  QuoterProps,
  QuoteSelectedValue,
} from "../../Modules/Quote/domain/QuoteSelected";
import Icon from "../Icon";
import { CodeKeys } from "../../Models/ModalInterface";
import { allowedCountries } from "../../Modules/Common/domain/Countries";
import { FlexContainer } from "../Flex/Flex.styled";
import { useCountry } from "../../Hooks/useCountry";
import { selectorLanguage } from "../../Redux/Translate";
import { quoterSelectStyles } from "./QuoterSelectTheme";
import { PersonsKYCSelector } from "../../Redux/PersonsKYC";
import { KYC_APPROVED, KYC_IN_REVIEW } from "../../Constants/KYCStatus";
import { Currency } from "../../Modules/Common/domain/Currency";
import { PlatformAppURL } from "../../Constants/ExternalServices";
import Text from "../Text";

const QuoterCard = ({
  deliveryMethodsSelect = [],
  full,
  sendToOptionsSelect = [],
  title,
  value,
  statusButton,
  maxAmount,
  minAmount,
  isDisabledButton,
  textButton,
  showVelocityLimit,
  codesMessages,
  handleSubmit,
  onChange,
}: QuoterProps) => {
  const [tSendMoney] = useTranslation("sendMoney");
  const [t] = useTranslation("global");
  const [tModals] = useTranslation("modals");
  const lang = useSelector(selectorLanguage);
  const personData = useSelector(PersonsKYCSelector).data;
  const person = personData?.personVeriff;
  const kycLevel2 = person?.kycLevelStatus?.find((kyc) => kyc.level === 2);

  const { countries } = useCountry();

  const [amount, setAmount] = useState<number | string>(value?.amount || 500);
  const [amountInputValue, setAmountInputValue] = useState<number | string>(
    amount
  );
  const [currency, setCurrency] = useState<string | undefined>(
    value?.currency ||
      (countries.find((c) => c.countryCode === "MEX")
        ? Currency.MXN
        : countries[0]?.defaultCurrency)
  );
  const [country, setCountry] = useState<string | undefined>(
    value?.country ||
      (countries.find((c) => c.countryCode === "MEX")
        ? "MEX"
        : countries[0]?.countryCode)
  );
  const [deliveryMethodsList, setDeliveryMethodsList] = useState<QuoteOption[]>(
    []
  );
  const [deliveryMethod, setDeliveryMethod] = useState<
    QuoteSelectedValue["deliveryMethod"]
  >(value?.deliveryMethod);
  const [sendTo, setSendTo] = useState<string | null>(value?.sendTo || null);

  const {
    currentQuotation,
    currentDeliveryMethodQuote,
    countryDestination,
    currentPaymentDestination,
  } = useSelector((state: State) => state.quotation.data);

  const maxExceeded = Boolean(maxAmount && Number(amount) > maxAmount);
  const minNotReached = Boolean(minAmount && Number(amount) < minAmount);
  const amountNotValid =
    Boolean(value?.amount) && (maxExceeded || minNotReached);

  const cantSubmit =
    !currentQuotation ||
    !currentDeliveryMethodQuote ||
    !countryDestination ||
    !currentPaymentDestination ||
    !allowedCountries.includes(countryDestination) ||
    amountNotValid ||
    !isEmpty(codesMessages) ||
    (Number(amount) > 999 && personData?.personVeriff?.status !== "approved");

  const messagesVelocity: CodeKeys = useMemo(
    () => ({
      VL001: tModals("velocityLimits.messagetxnDaily"),
      VL002: tModals("velocityLimits.messageTxnMonthly"),
      VL003: tModals("velocityLimits.messageAmountDaily"),
      VL004: tModals("velocityLimits.messageAmountMonthly"),
      VL007: tModals("velocityLimits.messageMinAmountDestination"),
      VL008: tModals("velocityLimits.messageMaxAmountDestination"),
      VL009: tModals("velocityLimits.messageAmountDestinationDaily"),
      VL010: tModals("velocityLimits.messageAmountDestinationMonthly"),
      VL013: tModals("velocityLimits.messageTxs"),
      VL014: tModals("velocityLimits.messageAmountTxs"),
      VL015: tModals("velocityLimits.messageCountrySubdivisionDaily", {
        limit: codesMessages?.find((item) => item.code === "VL015")?.value,
      }),
      VL016: tModals("velocityLimits.messageCountrySubdivisionMonthly", {
        limit: codesMessages?.find((item) => item.code === "VL016")?.value,
      }),
      VL017: tModals("velocityLimits.messageBasicKYCLimitDaily", {
        limit: codesMessages?.find((item) => item.code === "VL017")?.value,
      }),
      VL018: tModals("velocityLimits.messageBasicKYCLimitMonthly", {
        limit: codesMessages?.find((item) => item.code === "VL018")?.value,
      }),
      VL019: tModals("velocityLimits.messageBasicKYCDeclined"),
    }),
    [codesMessages, lang]
  );

  const totalCost = person?.applyFeePromotion
    ? Number(
        value?.currentQuote?.paymentMethods?.find(
          (paymentMethod) => paymentMethod.default === true
        )?.totalCost || "0"
      ) - Number(value?.currentQuote?.fee || "0")
    : value?.currentQuote?.paymentMethods?.find(
        (paymentMethod) => paymentMethod.default === true
      )?.totalCost || 0;

  const handleSubmitClick = () => {
    handleSubmit();
  };

  async function handleDeliveryMethod(optionSelected: SelectOption) {
    setSendTo(null);
    setDeliveryMethod(
      optionSelected.value as QuoteSelectedValue["deliveryMethod"]
    );
  }

  async function handleCurrencyChange(exchangeRate: tExchangeRate) {
    setSendTo(null);
    setCurrency(exchangeRate?.currency);
    setCountry(exchangeRate?.countryCode);
  }

  function handleBankInstitution(optionSelected: SelectOption) {
    setSendTo(optionSelected.value);
  }

  const setValues = () => {
    if (value?.amount) {
      setAmount(value.amount);
      setAmountInputValue(value.amount);
    }
    value?.currency && setCurrency(value.currency);
    value?.country && setCountry(value.country);
    getDeliveryMethod();
    setSendTo(value?.sendTo || null);
  };

  const resetValues = () => {
    setAmount(0);
    setCurrency(countries[1]?.currency);
    setCountry(countries[1]?.countryCode);
    setDeliveryMethod("D2B");
    getDeliveryMethod();
    setSendTo(null);
  };

  const triggerOnChange = useDebounce(() => {
    const newValue = {
      amount: Number(amount || "0"),
      currency,
      country,
      deliveryMethod,
      sendTo,
    };
    onChange && onChange(newValue, cantSubmit);
  }, 500);

  useEffect(() => {
    triggerOnChange();
  }, [amount, currency, country, deliveryMethod, sendTo, cantSubmit]);

  useEffect(() => {
    if (!isEmpty(value)) {
      setValues();
    } else {
      resetValues();
    }
  }, [value]);

  useEffect(() => {
    if (lang) {
      getDeliveryMethod();
    }
  }, [CountrieWhithoutD2B, CountrieWhithoutCP, country, lang]);

  const getDeliveryMethod = () => {
    if (country) {
      if (CountrieWhithoutCP.includes(country as string)) {
        const delivery = [deliveryMethodsSelect[0]];
        setDeliveryMethodsList(delivery);
        setDeliveryMethod("D2B");
      }
      if (CountrieWhithoutD2B.includes(country as string)) {
        const delivery = [deliveryMethodsSelect[1]];
        setDeliveryMethodsList(delivery);
        setDeliveryMethod("CPU");
      } else {
        setDeliveryMethodsList(deliveryMethodsSelect);
      }
    }
  };

  useEffect(() => {
    if (
      !isEmpty(countries) &&
      (!country || !countries.find((c) => c.countryCode === country))
    ) {
      setCurrency(countries[0]?.currency);
      setCountry(countries[0]?.countryCode);
    }
  }, [countries]);

  return (
    <>
      <Card as={CardMoney}>
        <Column span={12}>
          {title && (
            <Title
              text={title}
              color="black"
              size={1}
              tagName="h1"
              align="left"
            />
          )}
          {showVelocityLimit && (
            <ErrorVelocityLimitsStyled>
              <IconVelocityLimits>
                <Icon icon="alertTriangle" color="error" size="large" />
              </IconVelocityLimits>
              <TextVelocityLimits>
                {codesMessages &&
                  codesMessages.map((item, index) => (
                    <label key={index}>
                      {messagesVelocity[item.code] || t("global.unknownError")}
                    </label>
                  ))}
              </TextVelocityLimits>
            </ErrorVelocityLimitsStyled>
          )}
          <FlexContainer m="0 0 24px 0">
            <MoneyInput
              label={tSendMoney("Send")}
              amount={amountInputValue.toString()}
              data={SenderExchangeRates}
              errorLabel={
                maxExceeded
                  ? tSendMoney("errors.maxAmount", {
                      maxAmount: formatNumber(maxAmount || 0),
                    })
                  : minNotReached
                  ? tSendMoney("errors.minAmount", {
                      minAmount: formatNumber(minAmount || 0),
                    })
                  : undefined
              }
              handleInputChange={(e) => {
                setAmountInputValue(e.target.value);
              }}
              handleInputBlur={() => {
                setAmount(amountInputValue);
              }}
              maxLength={6}
            ></MoneyInput>
          </FlexContainer>
          {amountNotValid && (
            <ErrorVelocityLimitsStyled>
              <IconVelocityLimits>
                <Icon icon="alertTriangle" color="error" size="large" />
              </IconVelocityLimits>
              <TextVelocityLimits>
                {maxExceeded ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: tSendMoney("errors.maxAmount", {
                        maxAmount: formatNumber(maxAmount || 0),
                      }),
                    }}
                  />
                ) : minNotReached ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: tSendMoney("errors.minAmount", {
                        minAmount: formatNumber(minAmount || 0),
                      }),
                    }}
                  />
                ) : undefined}
              </TextVelocityLimits>
            </ErrorVelocityLimitsStyled>
          )}
          <FlexContainer m="0 0 17px 0">
            <MoneyInput
              label={tSendMoney("Recipient")}
              currency={currency}
              country={country}
              amount={value?.currentQuote?.amountToReceive?.toString() || "0"}
              data={countries}
              handleInputChange={() => false}
              handleCurrencyChange={handleCurrencyChange}
              disabled
            ></MoneyInput>
          </FlexContainer>
          {value?.currentQuote?.fx && (
            <ExchangeRate>
              <div>{tSendMoney("ExchangeRate")}:</div>
              <div>
                1.00 USD = {value.currentQuote.fx.toFixed(4)} {currency}
              </div>
            </ExchangeRate>
          )}
          {Number(amount) > 999 &&
            (!kycLevel2?.status || kycLevel2.status !== KYC_APPROVED) && (
              <ErrorVelocityLimitsStyled>
                <IconVelocityLimits>
                  <Icon icon="alertTriangle" color="error" size="large" />
                </IconVelocityLimits>
                <TextVelocityLimits>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: tModals("velocityLimits.messageKYCResubmission", {
                        href: PlatformAppURL,
                      }),
                    }}
                  />
                </TextVelocityLimits>
              </ErrorVelocityLimitsStyled>
            )}
          <SelectBankTitle>{tSendMoney("SelectTheBank")}</SelectBankTitle>
          <SearchSelect
            labelProps={{ color: "solid_light", size: 0 }}
            label={tSendMoney("DeliveryMethod")}
            options={deliveryMethodsList}
            name="country"
            onChange={handleDeliveryMethod}
            value={
              deliveryMethod
                ? deliveryMethodsList.find((c) => c.value === deliveryMethod)
                : null
            }
            customStyles={quoterSelectStyles}
            showAvatar
          />
          <>
            <SearchSelect
              labelProps={{ color: "solid_light", size: 0 }}
              label={tSendMoney(
                deliveryMethod === "D2B" ? "BankName" : "PickupLocation"
              )}
              options={sendToOptionsSelect}
              name="country"
              onChange={handleBankInstitution}
              value={
                sendTo
                  ? sendToOptionsSelect.find((c) => c.value === sendTo)
                  : null
              }
              customStyles={quoterSelectStyles}
              showAvatar
            />

            <TransferFeesStyled>
              <TransferFeeTitle>{tSendMoney("TransferFees")}</TransferFeeTitle>
              {person?.applyFeePromotion ? (
                <FlexContainer w="100%" justify="end" gap="6px">
                  <Text size={0.5} weight={500} color="solid_2">
                    {tSendMoney("WithoutCost")}
                  </Text>
                  <Text
                    size={0.5}
                    lineHeight="21px"
                    weight={500}
                    textDecoration="line-through"
                  >
                    $
                    {formatNumber(value?.currentQuote?.fee || 0, {
                      minimumFractionDigits: 2,
                    })}{" "}
                    USD
                  </Text>
                </FlexContainer>
              ) : (
                <TransferFeeAmount>
                  ${" "}
                  {formatNumber(value?.currentQuote?.fee || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </TransferFeeAmount>
              )}
            </TransferFeesStyled>
            <PaymentAmountStyled>
              <PaymentAmountLabel>
                {tSendMoney("TotalPayment")}
                <PaymentAmountSubLabel>
                  ({tSendMoney("Fees")})
                </PaymentAmountSubLabel>
              </PaymentAmountLabel>
              <PaymentAmountAmount>
                $ {formatNumber(totalCost, { minimumFractionDigits: 2 })} USD
              </PaymentAmountAmount>
            </PaymentAmountStyled>
            <ButtonSectionCard>
              <Button
                type="submit"
                variant={"gradient"}
                sizeButton="medium"
                sizeText="large"
                text={t(textButton)}
                iconButton="chevronRight"
                colorIcon="white"
                sizeIcon="large"
                onClick={handleSubmitClick}
                disabled={isDisabledButton || (cantSubmit && !!statusButton)}
              />
            </ButtonSectionCard>
          </>
        </Column>
      </Card>
      <ButtonSectionMobile>
        <Button
          type="submit"
          variant={"gradient"}
          sizeButton="large"
          sizeText="large"
          text={t(textButton)}
          iconButton="send"
          colorIcon="white"
          sizeIcon="large"
          onClick={handleSubmitClick}
          disabled={isDisabledButton || (cantSubmit && !!statusButton)}
        />
      </ButtonSectionMobile>
    </>
  );
};

export default QuoterCard;
