export const openApp = (link: string, fallbackLink?: string) => {
  let timeout: number;
  const abortController = new AbortController();
  let start: number;

  const openAppLink = () => {
    if (fallbackLink) {
      timeout = setTimeout(() => {
        window.location.href = fallbackLink;
      }, 1500) as unknown as number;
      start = Date.now();
      window.location.href = link;
    }
  };

  const clearFallback = function () {
    if (timeout && start && Date.now() - start < 500) {
      clearTimeout(timeout);
    }
    abortController.abort();
  };

  openAppLink();

  window.addEventListener("focus", clearFallback, {
    signal: abortController.signal,
  });
};

export const getUrl = (
  to: string,
  params?: Record<string, boolean | string>
) => {
  const url = new URL(to);
  const query = new URLSearchParams();

  if (params) {
    for (const [key, value] of Object.entries(params)) {
      if (typeof value === "boolean") {
        query.append(key, "1");
      } else {
        query.append(key, value);
      }
    }
  }

  url.search = query.toString();

  return url;
};

export const reditectTo = (
  to: string,
  params?: Record<string, boolean | string>
) => {
  const url = getUrl(to, params);

  window.location.href = url.toString();
};
