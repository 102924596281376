import styled from "styled-components";

import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { dividerColor } from "../../Contexts/ThemeProvider/Pages";
import { theme } from "styled-tools";

export const ButtonSubmitWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const ButtonContainer = styled(ButtonSubmitWrapper)`
  margin-bottom: 40px;
  width: min(100%, 330px);
  margin: auto auto 40px auto;
`;

export const PaypalButtonContainer = styled.div`
  width: 100%;
  margin: auto auto 40px auto;
  z-index: 0;
`;

export const TransactionLoaderContainer = styled.div`
  position: fixed;
  overflow: hidden;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 100%;
`;

export const ShieldCheck = styled.img`
  margin-right: auto;
  margin-left: auto;
  width: 50px;
  height: auto;
  margin-bottom: 24px;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 90%;
  background-color: ${dividerColor};
  margin-bottom: 1.5rem;
  margin-right: auto;
  margin-left: auto;

  ${mediaQuery[Screen.mobile]} {
    display: none;
  }
`;

export const disclaimerUniteller = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 24px 0;

  a {
    font-weight: 800;
    color: ${theme("Error")};
    text-decoration: underline;
    text-decoration-color: ${theme("Error")};
  }
`;
