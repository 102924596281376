import { useEffect, useMemo, useState } from "react";
import Text from "../../../../Components/Text";
import { useTranslation } from "react-i18next";
import { CountryCodeList } from "../../../../Modules/Common/domain/PhoneCountryCode";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { QuotationSelector } from "../../../../Redux/Quotation/Quotation.slice";
import FlagPhoneNumberInput from "../../../../Components/FlagPhoneNumberInput";
import { useCountry } from "../../../../Hooks/useCountry";
import { BeneficiarySelector } from "../../../../Redux/Beneficiary/Beneficiary.slice";
import { CountryCode } from "libphonenumber-js/core";
import { FormInput } from "../../../../Components/Input";
import { allowedEmail } from "../../../../Constants/Regexp";

export const ContactForm = () => {
  const { t } = useTranslation("global");
  const [phoneCountryCodeSelect, setPhoneCountryCodeSelect] =
    useState<string>();
  const quoteCountrySelected = useSelector(QuotationSelector).data
    .countryDestination as string;
  const beneficiaryCountrySelected =
    useSelector(BeneficiarySelector).data.countrySelected;
  const selectedCountry =
    beneficiaryCountrySelected?.countryCode || quoteCountrySelected;

  const { countriesWithoutUSAOptions } = useCountry();

  const phoneMinLenght = useMemo(() => {
    const minLengthPhone = CountryCodeList.find(
      (c) => c.countryCode === selectedCountry
    )?.phoneMinLength;

    return minLengthPhone;
  }, [phoneCountryCodeSelect]);

  const {
    formState: { errors },
    setValue,
    register,
  } = useFormContext();

  useEffect(() => {
    if (phoneCountryCodeSelect) {
      setValue("phoneCountryCode", phoneCountryCodeSelect);
    }
  }, [phoneCountryCodeSelect]);

  return (
    <>
      <Text
        size={2}
        lineHeight="24px"
        weight={600}
        align="left"
        margin="0 0 25px 2px"
      >
        {t("Beneficiaries.ContactTitle")}
      </Text>
      <FormInput
        placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.Email")}
        typeInput="email"
        label="email"
        rules={{
          pattern: {
            value: allowedEmail,
            message: t(
              "Beneficiaries.CreateBeneficiary.Validation.InvalidEmail"
            ),
          },
        }}
        mb="25px"
        isError={Boolean(errors?.["email"])}
      />
      <FlagPhoneNumberInput
        labelText={t("Beneficiaries.CreateBeneficiary.Form.PhoneNumber")}
        label="phoneNumber"
        rules={{
          required: t("Forms.required"),
          validate: (value: string) =>
            value.trim() === "" ? t("Forms.required") : true,
          maxLength: {
            value:
              CountryCodeList.find((c) => c.countryCode === selectedCountry)
                ?.phoneMaxLength || 10,
            message: t(
              "Beneficiaries.CreateBeneficiary.Form.MaxLengthPhoneNumber"
            ),
          },
          minLength: {
            value: phoneMinLenght || 10,
            message: t(
              "Beneficiaries.CreateBeneficiary.Form.MinLengthPhoneNumber",
              {
                minLength: phoneMinLenght || 10,
              }
            ),
          },
        }}
        register={register}
        errors={errors}
        countries={countriesWithoutUSAOptions.map(
          (c) => c.shortCode as CountryCode
        )}
        countryDefault={
          countriesWithoutUSAOptions.find((c) => c.value === selectedCountry)
            ?.shortCode as CountryCode
        }
        setCountryCode={setPhoneCountryCodeSelect}
        mb="25px"
      />
    </>
  );
};
