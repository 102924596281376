import styled from "styled-components";
import { FlexContainer } from "../Flex/Flex.styled";
import { theme } from "styled-tools";

export const CouponBarContainer = styled(FlexContainer)`
  justify-content: center;
  padding: 4.12px;
  color: ${theme("White")};
  background: ${theme("Gradient_Rewards")};
  border-radius: 4px;
  gap: 6px;
  box-shadow: 0px 0px 4px 0px ${theme("Black")}80;
`;
