import { Person, PersonCard, VerifyKYC } from "../domain/Person";
import { PersonRepository } from "../domain/PersonRepository";

export const getPerson =
  (personRepository: PersonRepository) => async (): Promise<Person> =>
    personRepository.getPerson();

export const getPersonCard =
  (personRepository: PersonRepository) => async (): Promise<PersonCard> =>
    personRepository.getPersonCard();

export const updateStatusVerifyKYC =
  (personRepository: PersonRepository) => async (): Promise<VerifyKYC> =>
    personRepository.updateStatusVerifyKYC();

export const getSelfieStatus = (personRepository: PersonRepository) => () =>
  personRepository.getSelfieStatus();
