import { useTranslation } from "react-i18next";
import {
  CPUInstructionsModalProps,
  ModalProps,
} from "../../../Models/ModalInterface";
import { FlexContainer } from "../../Flex/Flex.styled";
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import Modal from "../Modal.component";
import Text from "../../Text";
import IconShape from "../../IconShape";
import { SendolaColorSpan } from "../../Text/Text.styled";
import Button from "../../Button";

export const CPUInstructionsModal = (props: CPUInstructionsModalProps) => {
  const [t] = useTranslation("global");
  const [tModals] = useTranslation("modals");

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      <FlexContainer direction="column" gap="18px" m="0 0 30px 0">
        <Text size={1} weight={600} lineHeight="24px" align="left">
          {tModals("cpuInstructions.Title")}
        </Text>
        <Text size={0.5} weight={400} lineHeight="21px" align="left">
          {tModals("cpuInstructions.Description")}
        </Text>
      </FlexContainer>
      <FlexContainer direction="column" gap="24px">
        <FlexContainer gap="24px">
          <FlexContainer flex="1 1 auto" w="auto">
            <IconShape
              width={42}
              height={42}
              bgColor="disabled"
              icon="message"
              iconSize="medium"
              iconColor="primary"
            />
          </FlexContainer>
          <FlexContainer direction="column" gap="2px">
            <Text size={0.5} weight={600} lineHeight="16px">
              {tModals("cpuInstructions.steps.step1Title")}
            </Text>
            <Text size="default" weight={400} lineHeight="16px" align="left">
              <SendolaColorSpan
                dangerouslySetInnerHTML={{
                  __html: tModals("cpuInstructions.steps.step1Description", {
                    mtcn: props.mtcn,
                  }),
                }}
              ></SendolaColorSpan>
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer gap="24px">
          <FlexContainer flex="1 1 auto" w="auto">
            <IconShape
              width={42}
              height={42}
              bgColor="disabled"
              icon="file-text"
              iconSize="medium"
              iconColor="primary"
            />
          </FlexContainer>
          <FlexContainer direction="column" gap="2px">
            <Text size={0.5} weight={600} lineHeight="16px">
              {tModals("cpuInstructions.steps.step2Title")}
            </Text>
            <Text size="default" weight={400} lineHeight="16px" align="left">
              <SendolaColorSpan
                dangerouslySetInnerHTML={{
                  __html: tModals("cpuInstructions.steps.step2Description", {
                    rail: props.rail,
                  }),
                }}
              ></SendolaColorSpan>
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer gap="24px">
          <FlexContainer flex="1 1 auto" w="auto">
            <IconShape
              width={42}
              height={42}
              bgColor="disabled"
              icon="user-card-id"
              iconSize="medium"
              iconColor="primary"
            />
          </FlexContainer>
          <FlexContainer direction="column" gap="2px">
            <Text size={0.5} weight={600} lineHeight="16px">
              {tModals("cpuInstructions.steps.step3Title")}
            </Text>
            <Text size="default" weight={400} lineHeight="16px" align="left">
              <SendolaColorSpan
                dangerouslySetInnerHTML={{
                  __html: tModals("cpuInstructions.steps.step3Description"),
                }}
              ></SendolaColorSpan>
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer gap="24px">
          <FlexContainer flex="1 1 auto" w="auto">
            <IconShape
              width={42}
              height={42}
              bgColor="disabled"
              icon="location-outline"
              iconSize="medium"
              iconColor="primary"
            />
          </FlexContainer>
          <FlexContainer direction="column" gap="2px">
            <Text size={0.5} weight={600} lineHeight="16px">
              {tModals("cpuInstructions.steps.step4Title")}
            </Text>
            <Text size="default" weight={400} lineHeight="16px" align="left">
              <SendolaColorSpan
                dangerouslySetInnerHTML={{
                  __html: tModals("cpuInstructions.steps.step4Description", {
                    bankName: props.bankName,
                  }),
                }}
              ></SendolaColorSpan>
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <Button
            variant="primary"
            sizeButton="full"
            sizeText="large"
            text={t("TransactionCompleted.ActionButton")}
            onClick={() => props.handleConfirm && props.handleConfirm()}
          />
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
