createPaymentOrderPayPal;

import { useStatus } from "./useStatus";
import { Errors } from "../Modules/Common/domain/Errors";
import { PayPalApiPaymentOrderRepository } from "../Modules/PaymentOrders/infrastructure/ApiPaymentWithPayPalRepository";
import {
  createPaymentOrderPayPal,
  capturePaymentPayPal,
} from "../Modules/PaymentOrders/application/createPaymentOrder";

export const usePayPalPaymentOrder = () => {
  const { isLoading, setStatus, error, setError } = useStatus();

  const createPayPalPayment = async (amount: number, transactionId: string) => {
    setStatus("loading");
    try {
      const response = await createPaymentOrderPayPal(
        PayPalApiPaymentOrderRepository()
      )(amount, transactionId);

      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      if (error && (error as Errors).errors) {
        setError(error as Errors);
      }
    }
  };

  const capturePayPalPayment = async (paypalOrderId: string) => {
    try {
      const response = await capturePaymentPayPal(
        PayPalApiPaymentOrderRepository()
      )(paypalOrderId);

      return response;
    } catch (error) {
      setStatus("error");
      if (error && (error as Errors).errors) {
        setError(error as Errors);
      }
    }
  };

  return {
    error,
    isLoading,
    createPayPalPayment,
    capturePayPalPayment,
  };
};
