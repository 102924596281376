import { createClient } from "../../../Services/ApiClient";
import { Coupon, CouponGroup } from "../domain/Coupon";
import { CouponRepository } from "../domain/CouponRepository";

const client = createClient();

export const createApiCouponRepository = (): CouponRepository => {
  return {
    getCoupons,
  };
};

const getCoupons: CouponRepository["getCoupons"] = async () => {
  const response = await client.get<Coupon[]>("Rewards/user-coupons");

  const sortedCoupons = response.data.sort(
    (a, b) =>
      new Date(a.expirationDate).getTime() -
      new Date(b.expirationDate).getTime()
  );

  const couponsGrouped = sortedCoupons.reduce((groups, coupon) => {
    const couponGroup = groups.find((group) => group.code === coupon.code);
    if (couponGroup) {
      couponGroup.qty = couponGroup.qty + 1;
    } else {
      groups.push({
        code: coupon.code,
        description: coupon.description,
        qty: 1,
      });
    }

    return groups;
  }, [] as CouponGroup[]);

  return { coupons: response.data, couponsGrouped };
};
