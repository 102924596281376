import { PaymentOrderPayload } from "../domain/PaymentOrderCreated";
import {
  PaymentOrderRepository,
  PaymentOrderWithPayPalRepository,
  PaymentOrderWithSquareRepository,
} from "../domain/PaymentOrderRepository";

export const createPaymentOrder = (
  paymentOrderRepository: PaymentOrderRepository
) => {
  return async (payload: PaymentOrderPayload) => {
    const response = await paymentOrderRepository.createPaymentOrder(payload);

    return response;
  };
};

export const createPaymentOrderSquare = (
  paymentOrderRepository: PaymentOrderWithSquareRepository
) => {
  return async (id: string, paymentMethodFee: number) => {
    const response = await paymentOrderRepository.createPaymentLink(
      id,
      paymentMethodFee
    );

    return response;
  };
};

export const createPaymentOrderPayPal = (
  paymentOrderRepository: PaymentOrderWithPayPalRepository
) => {
  return async (amount: number, transactionId: string) => {
    const response = await paymentOrderRepository.createOrder(
      amount,
      transactionId
    );

    return response;
  };
};

export const capturePaymentPayPal = (
  paymentOrderRepository: PaymentOrderWithPayPalRepository
) => {
  return async (paypalOrderId: string) => {
    const response = await paymentOrderRepository.captureOrder(paypalOrderId);

    return response;
  };
};
