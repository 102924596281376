import styled, { css, CSSProperties } from "styled-components";
import { ifProp, prop } from "styled-tools";

export const ObjectFitImage = styled.img<{
  fit?: CSSProperties["objectFit"];
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  background?: CSSProperties["background"];
  gradientBackground?: CSSProperties["background"];
  objectPosition?: CSSProperties["objectPosition"];
  padding?: CSSProperties["padding"];
  rounded?: boolean;
}>`
  object-fit: ${prop("fit", "cover")};
  object-position: ${prop("objectPosition", "left")};
  width: ${prop("width", "100%")};
  height: ${prop("height", "auto")};
  border-radius: ${ifProp("rounded", "50%")};
  padding: ${prop("padding")};

  background: ${ifProp(
    "gradientBackground",
    css`linear-gradient(
        180deg,
        ${prop("gradientBackground")} 0%,
        ${prop("background")} 100%
      )`,
    prop("background")
  )};
`;

export const AccountLogo = styled.div<{
  backgroundColor?: string;
  gradientBackgroundColor?: string;
}>`
  width: 52px;
  height: 52px;
  border-radius: 50%;

  background: ${ifProp(
    "gradientBackgroundColor",
    css`linear-gradient(
        180deg,
        ${prop("gradientBackgroundColor")} 0%,
        ${prop("backgroundColor")} 100%
      )`
  )};
`;
