import theme from "styled-theming";

import { theme as config } from "./Theme";

const buttonBackgroundColor = theme.variants("currentTheme", "variant", {
  default: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  primary: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  secondary: {
    default: config.Background_01,
    martori: config.MartoriWhite,
  },
  danger: {
    default: config.Error,
    martori: config.Error,
  },
  gradient: {
    default: `linear-gradient(
        180deg,
        ${config.Primary_03} 0%,
        ${config.Primary_01} 100%
      );
    `,
    martori: `linear-gradient(
        180deg,
        ${config.MartoriGradient_01} 0%,
        ${config.MartoriGradient_02} 100%
      );
    `,
  },
  outline: {
    default: config.Transparent,
    martori: config.Transparent,
  },
  transparent: {
    default: config.Transparent,
    martori: config.Transparent,
  },
  zelle: {
    default: config.Zelle,
    martori: config.Zelle,
  },
  zelleGradient: {
    default: `linear-gradient(8.83deg, #680BAD 0.97%, #6E66FF 112.47%);`,
    martori: `linear-gradient(8.83deg, #680BAD 0.97%, #6E66FF 112.47%);`,
  },
  bannerBank: {
    default: `linear-gradient(8.83deg, #264093 0.97%, #0766E1 56.53%);`,
    martori: `linear-gradient(8.83deg, #264093 0.97%, #0766E1 56.53%);`,
  },
});

const buttonColor = theme.variants("currentTheme", "variant", {
  default: {
    default: config.Content_UX_03,
    martori: config.MartoriWhite,
  },
  primary: {
    default: config.Content_UX_03,
    martori: config.MartoriWhite,
  },
  secondary: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  danger: {
    default: config.Content_UX_03,
    martori: config.MartoriWhite,
  },
  gradient: {
    default: config.Content_UX_03,
    martori: config.MartoriWhite,
  },
  outline: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  transparent: {
    default: config.Black,
    martori: config.Black,
  },
  zelle: {
    default: config.White,
    martori: config.White,
  },
  zelleGradient: {
    default: config.White,
    martori: config.White,
  },
  bannerBank: {
    default: config.White,
    martori: config.White,
  },
});

const buttonBorderColor = theme.variants("currentTheme", "variant", {
  default: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  primary: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  outline: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  danger: {
    default: config.Error,
    martori: config.Error,
  },
  secondary: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  gradient: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  transparent: {
    default: config.Transparent,
    martori: config.Transparent,
  },
  zelle: {
    default: config.Transparent,
    martori: config.Transparent,
  },
  zelleGradient: {
    default: config.Transparent,
    martori: config.Transparent,
  },
  bannerBank: {
    default: config.Transparent,
    martori: config.Transparent,
  },
});

const buttonExternalLinkBackground = theme("currentTheme", {
  default: `linear-gradient(  
    270deg,
    ${config.Background_07} 0%,
    ${config.Background_08} 100%
  );
  `,
  martori: `linear-gradient(
    270deg,
    ${config.MartoriGradient_02} 0%,
    ${config.MartoriGradient_03} 60%,
    ${config.MartoriPrimary_01} 100%
  );
  `,
});

const buttonExternalLinkBorderColor = theme("currentTheme", {
  default: config.Cornflower_Blue,
  martori: config.MartoriGradient_01,
});

const buttonHoverColor = theme.variants("currentTheme", "variant", {
  default: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  primary: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  outline: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  danger: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  secondary: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  gradient: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
});

const buttonDisabledBackground = theme("currentTheme", {
  default: config.Content_UX_02,
  martori: config.Content_UX_02,
});

const buttonDisabledColor = theme("currentTheme", {
  default: config.Background_01,
  martori: config.Background_01,
});

const buttonDisabledBorderColor = theme("currentTheme", {
  default: config.Content_UX_02,
  martori: config.Content_UX_02,
});

const buttonOutlineDisabledColor = theme("currentTheme", {
  default: config.Content_UX_02,
  martori: config.Content_UX_02,
});

const buttonOutlineDisabledBackgroundColor = theme("currentTheme", {
  default: config.Background_01,
  martori: config.Content_UX_02,
});

export {
  buttonBackgroundColor,
  buttonColor,
  buttonBorderColor,
  buttonExternalLinkBackground,
  buttonExternalLinkBorderColor,
  buttonDisabledBackground,
  buttonDisabledColor,
  buttonDisabledBorderColor,
  buttonOutlineDisabledColor,
  buttonOutlineDisabledBackgroundColor,
};
