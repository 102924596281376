import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BeneficiarySelector,
  BeneficiarySlice,
} from "../../../Redux/Beneficiary/Beneficiary.slice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../Hooks/useModal";
import Layout from "../../../Components/Layout";
import { ThemeProvider } from "styled-components";
import theme from "../../../Contexts/ThemeProvider";
import { scrollTopWindow } from "../../../Utilities/ScrollTopWindow";
import { Column, Container, Row } from "../../../Components/Grid";
import {
  Controller,
  FieldValues,
  FormProvider,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import Button from "../../../Components/Button";
import { useBeneficiaryAdditionalFields } from "../../../Hooks/useBeneficiaryAdditionalFields";
import { QuotationSelector } from "../../../Redux/Quotation/Quotation.slice";
import { DynamicInput } from "../../../Components/DynamicInput";
import isEmpty from "lodash.isempty";
import {
  getStatusPersonKYC,
  PersonsKYCSelector,
} from "../../../Redux/PersonsKYC";
import { AdditionalFieldInfo } from "../../../Modules/Beneficiary/domain/BeneficiaryForm";
import { AppDispatch } from "../../../Redux/Store";
import { GET_PERSON_ID_FULFILLED } from "../../../Constants/Person";
import { BeneficiaryEdit } from "../../../Modules/Beneficiary/domain/Beneficiary";
import { useBeneficiary } from "../../../Hooks/useBeneficiary";
import Text from "../../../Components/Text";
import { SearchSelect } from "../../../Components/SearchSelect/SearchSelect.component";
import { FormInput } from "../../../Components/Input";
import { useCountry } from "../../../Hooks/useCountry";
import { useSubdivision } from "../../../Hooks/useSubdivision";
import { useCity } from "../../../Hooks/useCity";
import { CountryCodeList } from "../../../Modules/Common/domain/PhoneCountryCode";
import { SelectInstance } from "react-select";
import { SelectOption } from "../../../Models/Select";
import { selectorLanguage } from "../../../Redux/Translate";
import { allowedCountries } from "../../../Modules/Common/domain/Countries";
import {
  allowedCompoundNames,
  allowedEmail,
  allowedNumbers,
} from "../../../Constants/Regexp";
import FlagPhoneNumberInput from "../../../Components/FlagPhoneNumberInput";
import { CountryCode } from "libphonenumber-js/core";
import { BeneficiaryEditHead } from "../Components/BeneficiaryEditHead/BeneficiaryEditHead.component";
import { DateInput } from "../../../Components/DateInput/DateInput.component";
import { AdditionalField } from "../../../Modules/Common/domain/AdditionalFields";
import {
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input/input";
import { DashboardSelector } from "../../../Redux/Dashboard/Dashboard.slice";

export const EditBeneficiary = () => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { language } = useSelector(selectorLanguage);
  const [beneficiary, setBeneficiary] = useState<BeneficiaryEdit>();
  const {
    data: { countrySelected: beneficiaryCountrySelected },
  } = useSelector(BeneficiarySelector);
  const {
    data: {
      countryDestination: quoteCountrySelected,
      currentPaymentDestination,
    },
  } = useSelector(QuotationSelector);
  const { data: personKYCData } = useSelector(PersonsKYCSelector);
  const currentBeneficiarySelected =
    useSelector(BeneficiarySelector).data?.beneficiaryEdit;

  const [selectedCountry, setSelectedCountry] = useState(
    beneficiaryCountrySelected?.countryCode || quoteCountrySelected
  );

  const [phoneCountryCodeSelect, setPhoneCountryCodeSelect] =
    useState<string>();
  const [subdivisionsList, setSubdivisionList] = useState<SelectOption[]>([]);

  const { modal, showModal, setOpenModal } = useModal();
  const [showButtonDelete, setShowButtonDelete] = useState(false);
  const form = useForm<any>({ mode: "onSubmit", values: beneficiary });
  const {
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    getValues,
    control,
    register,
    trigger,
  } = form;

  const { previousView } = useSelector(DashboardSelector);
  const {
    additionalFieldsAppriza,
    fetchAdditionalFieldsAppriza,
    isLoading: loadingAdditionalAppriza,
  } = useBeneficiaryAdditionalFields({
    country: "",
  });
  const {
    beneficiaryInformationId,
    isLoading: loadingBeneficiary,
    getBeneficiaryInformation,
    deleteBeneficiarySelected,
  } = useBeneficiary({ form: form as unknown as UseFormReturn<FieldValues> });

  const {
    additionalFields: beneficiaryAdditionalFields,
    isLoading: loadingAdditionalFields,
  } = useBeneficiaryAdditionalFields({
    country: selectedCountry || "",
    destinationSelected: currentPaymentDestination,
    transferType: "CPU",
    includeBranchFields: false,
    form: form as unknown as UseFormReturn<FieldValues>,
  });

  const { countriesWithoutUSAOptions, isLoading: countryIsLoading } =
    useCountry();
  const {
    subdivisionList,
    isLoading: subdivisionIsLoading,
    getSubdivisionOptions,
  } = useSubdivision();
  const {
    citiesList,
    isLoading: cityIsLoading,
    getSubdivisionCitiesOptions,
  } = useCity();

  const subdivRef = useRef<SelectInstance<SelectOption> | null>(null);
  const destinationRef = useRef<SelectInstance<SelectOption> | null>(null);
  const cityRef = useRef<SelectInstance<SelectOption> | null>(null);

  const loading =
    loadingAdditionalFields ||
    loadingAdditionalAppriza ||
    loadingBeneficiary ||
    countryIsLoading ||
    subdivisionIsLoading ||
    cityIsLoading;

  const processSubmit = handleSubmit(async (data) => {
    trigger();
    let personId = personKYCData?.personVeriff?.personId;
    if (!personId) {
      const userAuthPerson = await dispatch(getStatusPersonKYC());
      if (userAuthPerson.type === GET_PERSON_ID_FULFILLED) {
        personId = userAuthPerson.payload?.toString();
      }
    }

    const date = data.dateOfBirth.split("/");

    if (personId) {
      const formData = {
        ...data,
        email: data.email ? data.email.trim() : null,
        phoneNumber: `${data.phoneCountryCode}${data.phoneNumber}`,
        dateOfBirth: `${date[2]}-${date[1]}-${date[0]}`,
      };

      formData.additionalField = {
        additionalFieldInfo:
          additionalFieldsAppriza.map((field) => ({
            fieldName: field.fieldName,
            fieldValue: String(getValues(`${field.fieldName}`)),
            fieldBelongsTo: field.fieldBelongsTo,
          })) || [],
      };

      formData.accounts = beneficiary?.accounts;

      dispatch(
        BeneficiarySlice.actions.setBeneficiaryEdit({
          ...formData,
        })
      );
      navigate("/accounts", {
        state: {
          view: "edit",
        },
      });
    } else {
      navigate(-1);
    }
  });

  useEffect(() => {
    if (selectedCountry) {
      fetchAdditionalFieldsAppriza(selectedCountry);
      if (language) {
        if (allowedCountries.includes(selectedCountry)) {
          getSubdivisionOptions(selectedCountry, language);
        }
      }
    }
  }, [selectedCountry, language]);

  useEffect(() => {
    scrollTopWindow();
    getBeneficiaryId();
  }, []);

  useEffect(() => {
    if (beneficiary) setShowButtonDelete(beneficiary?.accounts?.length === 0);
  }, []);

  const beneficiaryInformation = async () => {
    if (beneficiaryInformationId && phoneCountryCodeSelect) {
      const countryPhone = parsePhoneNumber(
        beneficiaryInformationId.phoneNumber
      );
      const phoneNumber = countryPhone?.nationalNumber;
      setPhoneCountryCodeSelect(beneficiaryInformationId?.country);
      if (countryPhone?.country) {
        setValue("phoneNumber", phoneNumber);
        const strPhoneCode = `+${getCountryCallingCode(countryPhone.country)}`;
        setValue("phoneCountryCode", strPhoneCode);
      }
      setBeneficiary({
        ...beneficiaryInformationId,
        phoneNumber: phoneNumber || beneficiaryInformationId.phoneNumber,
      });
      const date = beneficiaryInformationId.dateOfBirth.split("-");
      if (date.length === 3) {
        setValue("dateOfBirth", `${date[2]}/${date[1]}/${date[0]}`, {
          shouldValidate: true,
        });
      }
      setSelectedCountry(beneficiaryInformationId?.country);
      setValue("country", beneficiaryInformationId.country, {
        shouldValidate: true,
      });

      if (allowedCountries.includes(beneficiaryInformationId?.country)) {
        const subdivisions = await getSubdivisionOptions(
          beneficiaryInformationId.country,
          language
        );
        if (subdivisions && subdivisionList) {
          setValue("subnational", beneficiaryInformationId.subnational);
          setSubdivisionList(subdivisions);
        }

        await getSubdivisionCitiesOptions(beneficiaryInformationId.subnational);
        setValue("city", beneficiaryInformationId.city);
      } else {
        setSubdivisionList([]);
      }
    }
  };

  useEffect(() => {
    const phoneNumber = currentBeneficiarySelected?.phoneNumber;
    if (phoneNumber) {
      const countryPhone = parsePhoneNumber(phoneNumber);
      if (countryPhone && countryPhone.country) {
        setValue("phoneNumber", countryPhone.nationalNumber);
        const strPhoneCode = `+${getCountryCallingCode(countryPhone.country)}`;
        setValue("phoneCountryCode", strPhoneCode);
      }
    }
  }, [phoneCountryCodeSelect]);

  useEffect(() => {
    (async () => {
      if (beneficiaryInformationId && !beneficiary) {
        await beneficiaryInformation();
      }
    })();
  }, [beneficiaryInformationId]);

  const getBeneficiaryId = async () => {
    if (currentBeneficiarySelected?.beneficiaryId) {
      await getBeneficiaryInformation(currentBeneficiarySelected.beneficiaryId);
    }
  };

  const zipCodeMinLenght = useMemo(() => {
    const minLength =
      CountryCodeList.find((c) => c.countryCode === selectedCountry)
        ?.zipCodeLength || 5;

    return minLength;
  }, [selectedCountry]);

  const phoneMinLenght = useMemo(() => {
    const minLengthPhone = CountryCodeList.find(
      (c) => c.countryCode === selectedCountry
    )?.phoneMinLength;

    return minLengthPhone;
  }, [phoneCountryCodeSelect]);

  const onChangeCountry = async (value: string) => {
    setSelectedCountry(value);
    if (selectedCountry) {
      setSelectedCountry(value);
    }
    setValue("country", "");
    destinationRef.current?.clearValue();

    setValue("subnational", "");
    subdivRef.current?.clearValue();

    const subdivisions = await getSubdivisionOptions(value, language);
    if (subdivisions && subdivisionList) {
      setSubdivisionList(subdivisions);
    }
  };

  const onChangeSubdivision = async (value: string) => {
    setValue("city", "");
    cityRef.current?.clearValue();
    if (value) {
      await getSubdivisionCitiesOptions(value);
    }
  };

  const handleDeleteBeneficiary = () => {
    if (beneficiary) {
      showModal({
        modalType: "confirmationModal",
        title: t("Beneficiaries.EditBeneficiary.titleModalDelete"),
        message: t("Beneficiaries.EditBeneficiary.subTitleModalDelete"),
        handleConfirm: () => {
          deleteBeneficiary(beneficiary?.beneficiaryId);
        },
      });
    }
  };

  const deleteBeneficiary = async (beneficiaryId: string) => {
    if (beneficiaryId) {
      await deleteBeneficiarySelected(beneficiaryId);
      setOpenModal(true);
      navigate("/dashboard", {
        state: { activeTab: "recipients" },
      });
    }
  };

  const getAdditionalFieldDefault = (field: AdditionalField) => {
    return (
      beneficiary?.additionalField?.additionalFieldInfo &&
      beneficiary?.additionalField?.additionalFieldInfo.find(
        (additionalField: AdditionalFieldInfo) =>
          additionalField.fieldName === field.fieldName.split(".").reverse()[0]
      )?.fieldValue
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout loading={loading} roundedContent={false}>
        <FormProvider {...form}>
          <form onSubmit={processSubmit}>
            <Container maxWidth="393px">
              <Row>
                <Column span={12} px={3} mt={2} mb={3}>
                  <BeneficiaryEditHead
                    onHandleDelete={() => handleDeleteBeneficiary()}
                    onClose={() =>
                      navigate("/dashboard", {
                        state: {
                          activeTab:
                            previousView === "dashboard"
                              ? "dashboard"
                              : "recipients",
                        },
                      })
                    }
                    showDelete={beneficiary?.accounts?.length === 0}
                  />
                </Column>
              </Row>
              <Row>
                <Column span={12} px={3} mb={2}>
                  <Text
                    size={2}
                    lineHeight="24px"
                    weight={600}
                    align="left"
                    margin="0 0 25px 2px"
                  >
                    {t("Beneficiaries.ProfileTitle")}
                  </Text>
                  <FormInput
                    placeholderInput={t(
                      "Beneficiaries.CreateBeneficiary.Form.FirstName"
                    )}
                    label="firstName"
                    rules={{
                      required: t("Forms.required"),
                      validate: (value) =>
                        value.trim() === "" ? t("Forms.required") : true,
                    }}
                    mb="25px"
                    isError={Boolean(errors?.["firstName"])}
                  />
                  <FormInput
                    placeholderInput={t(
                      "Beneficiaries.CreateBeneficiary.Form.MiddleName"
                    )}
                    label="middleName"
                    rules={{
                      pattern: {
                        value: allowedCompoundNames,
                        message: t("Forms.inputAlphabets"),
                      },
                    }}
                    mb="25px"
                  />
                  <FormInput
                    placeholderInput={t(
                      "Beneficiaries.CreateBeneficiary.Form.LastName"
                    )}
                    label="lastName"
                    rules={{
                      required: t("Forms.required"),
                      validate: (value) =>
                        value.trim() === "" ? t("Forms.required") : true,
                    }}
                    mb="25px"
                    isError={Boolean(errors?.["lastName"])}
                  />
                  <FormInput
                    placeholderInput={t(
                      "Beneficiaries.CreateBeneficiary.Form.Alias"
                    )}
                    label="alias"
                    rules={{}}
                    infoText={t(
                      "Beneficiaries.CreateBeneficiary.Form.AliasHint"
                    )}
                    mb="25px"
                  />

                  <Controller
                    control={control}
                    name="dateOfBirth"
                    rules={{ required: t("Forms.required") }}
                    render={({ field: { value, onChange } }) => (
                      <DateInput
                        label={t(
                          "Beneficiaries.CreateBeneficiary.Form.DateOfBirth"
                        )}
                        name="dateOfBirth"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  <hr />
                </Column>
              </Row>
              <Row>
                <Column span={12} px={3} mb={2}>
                  <Text
                    size={2}
                    lineHeight="24px"
                    weight={600}
                    align="left"
                    margin="0 0 25px 2px"
                  >
                    {t("Beneficiaries.ContactTitle")}
                  </Text>
                  <FormInput
                    placeholderInput={t(
                      "Beneficiaries.CreateBeneficiary.Form.Email"
                    )}
                    typeInput="email"
                    label="email"
                    rules={{
                      pattern: {
                        value: allowedEmail,
                        message: t(
                          "Beneficiaries.CreateBeneficiary.Validation.InvalidEmail"
                        ),
                      },
                    }}
                    mb="25px"
                    isError={Boolean(errors?.["email"])}
                  />
                  <FlagPhoneNumberInput
                    labelText={t(
                      "Beneficiaries.CreateBeneficiary.Form.PhoneNumber"
                    )}
                    label="phoneNumber"
                    rules={{
                      required: t("Forms.required"),
                      validate: (value: string) =>
                        value.trim() === "" ? t("Forms.required") : true,
                      maxLength: {
                        value:
                          CountryCodeList.find(
                            (c) => c.countryCode === selectedCountry
                          )?.phoneMaxLength || 10,
                        message: t(
                          "Beneficiaries.CreateBeneficiary.Form.MaxLengthPhoneNumber"
                        ),
                      },
                      minLength: {
                        value: phoneMinLenght || 10,
                        message: t(
                          "Beneficiaries.CreateBeneficiary.Form.MinLengthPhoneNumber",
                          {
                            minLength: phoneMinLenght || 10,
                          }
                        ),
                      },
                      pattern: {
                        value: allowedNumbers,
                        message: t("Forms.inputNumeric"),
                      },
                    }}
                    register={register}
                    errors={errors}
                    countries={countriesWithoutUSAOptions.map(
                      (c) => c.shortCode as CountryCode
                    )}
                    countryDefault={
                      countriesWithoutUSAOptions.find(
                        (c) => c.value === beneficiaryInformationId?.country
                      )?.shortCode as CountryCode
                    }
                    setCountryCode={setPhoneCountryCodeSelect}
                    mb="25px"
                  />
                  <hr />
                </Column>
              </Row>
              <Row>
                <Column span={12} px={3} mb={2}>
                  <Text
                    size={2}
                    lineHeight="24px"
                    weight={600}
                    align="left"
                    margin="0 0 25px 2px"
                  >
                    {t("Beneficiaries.AddressTitle")}
                  </Text>

                  <Controller
                    control={control}
                    name="country"
                    rules={{ required: t("Forms.required") }}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <>
                        <SearchSelect
                          errors={errors}
                          placeholderInput={t(
                            "Beneficiaries.CreateBeneficiary.Form.Country"
                          )}
                          options={countriesWithoutUSAOptions}
                          name="country"
                          value={countriesWithoutUSAOptions.find(
                            (c) => c.value === value
                          )}
                          m="0 0 25px 0"
                          showAvatar
                          showAvatarOnValue
                          onBlur={onBlur}
                          onChange={(sel) => {
                            if (sel?.value) {
                              onChange(sel.value);
                              onChangeCountry(sel.value);
                            }
                          }}
                        />
                      </>
                    )}
                  />

                  <Controller
                    control={control}
                    name="subnational"
                    rules={{ required: t("Forms.required") }}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <>
                        <SearchSelect
                          selectRef={subdivRef}
                          errors={errors}
                          placeholderInput={t(
                            "Beneficiaries.CreateBeneficiary.Form.State"
                          )}
                          options={subdivisionList}
                          name="subdivision"
                          placeholder=""
                          value={subdivisionList.find((s) => s.value === value)}
                          m="0 0 25px 0"
                          onBlur={onBlur}
                          onChange={(sel) => {
                            onChange(sel?.value);
                            onChangeSubdivision(sel?.value);
                          }}
                        />
                      </>
                    )}
                  />

                  <Controller
                    control={control}
                    name="city"
                    rules={{ required: t("Forms.required") }}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <SearchSelect
                        errors={errors}
                        selectRef={cityRef}
                        placeholderInput={t(
                          "Beneficiaries.CreateBeneficiary.Form.City"
                        )}
                        name="city"
                        placeholder=""
                        options={citiesList}
                        m="0 0 25px 0"
                        value={citiesList.find((s) => s.value === value)}
                        onBlur={onBlur}
                        onChange={(sel) => onChange(sel?.value || "")}
                      />
                    )}
                  />

                  <FormInput
                    placeholderInput={
                      selectedCountry &&
                      ["MEX", "GTM"].includes(selectedCountry)
                        ? t(
                            "Beneficiaries.CreateBeneficiary.Form.AddressStreet"
                          )
                        : t("Beneficiaries.CreateBeneficiary.Form.Address1")
                    }
                    label="address"
                    rules={{
                      required: t("Forms.required"),
                      validate: (value) =>
                        value.trim() === "" ? t("Forms.required") : true,
                    }}
                    mb="25px"
                    isError={Boolean(errors?.["address"])}
                  />

                  <FormInput
                    placeholderInput={
                      selectedCountry &&
                      ["MEX", "GTM"].includes(selectedCountry)
                        ? t(
                            "Beneficiaries.CreateBeneficiary.Form.AddressApartment"
                          )
                        : t("Beneficiaries.CreateBeneficiary.Form.Address2")
                    }
                    label="addressExtra"
                    rules={{}}
                    mb="25px"
                  />

                  <FormInput
                    placeholderInput={t(
                      "Beneficiaries.CreateBeneficiary.Form.PostalCode"
                    )}
                    maxLength={zipCodeMinLenght}
                    label="postalCode"
                    rules={{
                      required: t("Forms.required"),
                      minLength: {
                        value: zipCodeMinLenght,
                        message: t(
                          "Beneficiaries.CreateBeneficiary.Form.PostalCodeMinLength",
                          {
                            minLength: zipCodeMinLenght,
                          }
                        ),
                      },
                      validate: (value) =>
                        value.trim() === "" ? t("Forms.required") : true,
                    }}
                    isError={Boolean(errors?.["postalCode"])}
                  />
                </Column>
              </Row>
              {!isEmpty(beneficiaryAdditionalFields) && (
                <Row>
                  <Column span={12} px={3} mb={2}>
                    <hr />
                  </Column>
                  <Column span={12} px={3} mb={2}>
                    {beneficiaryAdditionalFields.map((field) => (
                      <DynamicInput key={field.fieldName} {...field} />
                    ))}
                  </Column>
                </Row>
              )}
              {!isEmpty(additionalFieldsAppriza) && (
                <Row>
                  <Column span={12} px={3} mb={2}>
                    <hr />
                  </Column>
                  <Column span={12} px={3} mb={2}>
                    {additionalFieldsAppriza.map((field) => (
                      <DynamicInput
                        key={field.fieldName}
                        {...field}
                        defaultValue={getAdditionalFieldDefault(field)}
                      />
                    ))}
                  </Column>
                </Row>
              )}
              <Row>
                <Column span={12} px={3} mb={4}>
                  <Button
                    variant="primary"
                    sizeButton="full"
                    text={t("global.continue")}
                    sizeText="medium"
                    iconButton="arrowRight"
                    colorIcon="white"
                    sizeIcon="large"
                    padding="8px"
                    type="submit"
                  />
                </Column>
              </Row>
            </Container>
          </form>
        </FormProvider>
        {modal}
      </Layout>
    </ThemeProvider>
  );
};
