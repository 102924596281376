import { ModalProps } from "../../../Models/ModalInterface";
import Modal from "../Modal.component";
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { useTranslation } from "react-i18next";
import Button from "../../Button";
import { SendolaColorSpan } from "../../Text/Text.styled";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

export const FreeTransactionModal = (props: ModalProps) => {
  const [tSendMoney] = useTranslation("sendMoney");
  return (
    <Modal
      {...props}
      hiddenCloseIcon={false}
      as={BottomModalWrapper}
      zIndex={props.zIndex}
    >
      <FlexContainer direction="column" alignItems="center" gap="15px">
        <Text size={1} weight={500}>
          <SendolaColorSpan
            dangerouslySetInnerHTML={{
              __html: tSendMoney("freeTransferDescription1"),
            }}
          ></SendolaColorSpan>
        </Text>
        <DotLottieReact src="/gift.lottie" autoplay />
        <Text size={1} weight={600}>
          {tSendMoney("freeTransferDescription2")}
        </Text>
        <Button
          text={tSendMoney("sendNow")}
          sizeText="default"
          sizeButton="full"
          variant="zelleGradient"
          padding="9px"
          onClick={() => props.handleClose()}
        />
      </FlexContainer>
    </Modal>
  );
};
