import USAFlag from "../Assets/Icons/USA-flag.svg?react";
import AlertTriangle from "../Assets/Icons/alert-triangle.svg?react";
import ArrowLeft from "../Assets/Icons/arrow-narrow-left.svg?react";
import ArrowRight from "../Assets/Icons/arrow-narrow-right.svg?react";
import ArrowTop from "../Assets/Icons/arrow-top.svg?react";
import ArrowDown from "../Assets/Icons/arrow-down.svg?react";
import Bag from "../Assets/Icons/bag.svg?react";
import Bank from "../Assets/Icons/bank.svg?react";
import Bell from "../Assets/Icons/bell.svg?react";
import Cash from "../Assets/Icons/cash.svg?react";
import CashCancel from "../Assets/Icons/cash-cancel.svg?react";
import ChevronDown from "../Assets/Icons/chevron-down.svg?react";
import ChevronRight from "../Assets/Icons/chevron-right.svg?react";
import CircleCheck from "../Assets/Icons/circle-check.svg?react";
import CircleXRed from "../Assets/Icons/circle-x-red.svg?react";
import CircleXWarning from "../Assets/Icons/circle-x-warning.svg?react";
import CirlePlus from "../Assets/Icons/circle-plus.svg?react";
import CircleX from "../Assets/Icons/circle-x.svg?react";
import CircleUser from "../Assets/Icons/circle-user.svg?react";
import CoinGradient from "../Assets/Icons/coin-gradient.svg?react";
import Coin from "../Assets/Icons/coin.svg?react";
import Copy from "../Assets/Icons/copy.svg?react";
import CreditCard from "../Assets/Icons/credit-card.svg?react";
import EyeOff from "../Assets/Icons/eye-off.svg?react";
import Eye from "../Assets/Icons/eye.svg?react";
import CircleInfo from "../Assets/Icons/info-circle.svg?react";
import Lock from "../Assets/Icons/lock.svg?react";
import Menu from "../Assets/Icons/menu.svg?react";
import Message from "../Assets/Icons/message.svg?react";
import Plus from "../Assets/Icons/plus.svg?react";
import Send from "../Assets/Icons/send.svg?react";
import ShieldCheck from "../Assets/Icons/shield-check.svg?react";
import Upload from "../Assets/Icons/upload.svg?react";
import User from "../Assets/Icons/user.svg?react";
import Group from "../Assets/Icons/users.svg?react";
import PriceCoin from "../Assets/Icons/price-comparison-coin.svg?react";
import CircleSelected from "../Assets/Icons/circle-selected.svg?react";
import CircleDot from "../Assets/Icons/circle-dot.svg?react";
import Wallet from "../Assets/Icons/wallet.svg?react";
import Download from "../Assets/Icons/download.svg?react";
import Share from "../Assets/Icons/share.svg?react";
import ChevronUp from "../Assets/Icons/chevron-up.svg?react";
import Settings from "../Assets/Icons/settings.svg?react";
import Logout from "../Assets/Icons/log-out.svg?react";
import SendGradient from "../Assets/Icons/send_gradient.svg?react";
import CreditCardGradient from "../Assets/Icons/credit-card_gradient.svg?react";
import ShieldCheckGradient from "../Assets/Icons/shield-check_gradient.svg?react";
import Question from "../Assets/Icons/question.svg?react";
import Headset from "../Assets/Icons/headset.svg?react";
import Edit from "../Assets/Icons/edit.svg?react";
import Trash from "../Assets/Icons/trash.svg?react";
import Star from "../Assets/Icons/star.svg?react";
import RecipientSuccess from "../Assets/Icons/recipient-success.svg?react";
import CircleOne from "../Assets/Icons/circle-1.svg?react";
import CircleTwo from "../Assets/Icons/circle-2.svg?react";
import Location from "../Assets/Icons/location.svg?react";
import SimpCash from "../Assets/Icons/simp-cash.svg?react";
import CircleWarning from "../Assets/Icons/warning-circle.svg?react";
import UserX from "../Assets/Icons/user-x.svg?react";
import SendolaPayLogo from "../Assets/Icons/sendola-pay-icon.svg?react";
import DownArrow from "../Assets/Icons/down-arrow.svg?react";
import IconX from "../Assets/Icons/icon-x.svg?react";
import Clock from "../Assets/Icons/clock.svg?react";

import bgTransferLoader from "../Assets/Img/bgTransferLoader.png";
import Login from "../Assets/Img/bgLogin.png";
import bgCardSendMoney from "../Assets/Img/bgCardSendMoney.png";
import bgConfirmation from "../Assets/Img/bgConfirmation.png";
import bgLetterSendola from "../Assets/Img/bgLetterSendola.png";
import locationPermission from "../Assets/Img/location-permission.png";
import logoUniteller from "../Assets/Img/uniteller.svg";

import { IconSize, IconTypes, ImageTypes } from "../Models/IconInterface";

import { textComponentSolidlight } from "../Contexts/ThemeProvider/Texts";
import { theme } from "styled-tools";

export const iconFontTypes = [
  "alert",
  "arrow-left",
  "arrow-right",
  "arrows",
  "average-smile",
  "bell",
  "building-bank",
  "calendar-event",
  "camera",
  "cash",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "circle-check",
  "circle-exclamation",
  "circle-minus",
  "circle-plus",
  "circle-x",
  "coin",
  "copy",
  "clock",
  "credit-card",
  "diagonal-arrow",
  "download",
  "edit",
  "email",
  "equal",
  "eye-off",
  "eye",
  "external-link",
  "faqs",
  "fulled-star",
  "home",
  "iconsax-linear-bill",
  "iconsax-linear",
  "info",
  "link",
  "location",
  "lock",
  "logout",
  "menu",
  "message",
  "phone-assistance",
  "phone",
  "picture",
  "pin",
  "plus",
  "resize",
  "sad-smile",
  "search",
  "send",
  "sendola-pay",
  "settings",
  "share",
  "shield-check",
  "star",
  "step-one",
  "step-three",
  "step-two",
  "transaction",
  "trash",
  "user-card-id",
  "user-check",
  "user-exclamation",
  "user-id",
  "user-plus",
  "user-x",
  "user",
  "wallet",
  "arrows-flat",
  "user-rounded",
  "user-circle",
  "settings-bars",
  "cancel",
  "hand-coin",
  "safe",
  "pencil",
  "currency-dollar",
  "device-mobile",
  "gift",
  "play",
  "sending-plane",
  "file-text",
  "sand-clock",
  "location-outline",
  "discount-percent-flame",
] as const;

export const iconTypes = {
  alertTriangle: AlertTriangle,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  bag: Bag,
  bell: Bell,
  cash: Cash,
  cashCancel: CashCancel,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  chevronRight: ChevronRight,
  circleCheck: CircleCheck,
  circlePlus: CirlePlus,
  circleX: CircleX,
  circleUser: CircleUser,
  creditCard: CreditCard,
  coin: Coin,
  coinGradient: CoinGradient,
  eye: Eye,
  eyeOff: EyeOff,
  downArrow: DownArrow,
  menu: Menu,
  message: Message,
  send: Send,
  shieldCheck: ShieldCheck,
  upload: Upload,
  user: User,
  bank: Bank,
  group: Group,
  copy: Copy,
  arrowTop: ArrowTop,
  plus: Plus,
  circleInfo: CircleInfo,
  usaFlag: USAFlag,
  lock: Lock,
  arrowDown: ArrowDown,
  priceCoin: PriceCoin,
  circleDot: CircleDot,
  circleSelected: CircleSelected,
  wallet: Wallet,
  download: Download,
  share: Share,
  settings: Settings,
  logout: Logout,
  sendGradient: SendGradient,
  creditCardGradient: CreditCardGradient,
  shieldCheckGradient: ShieldCheckGradient,
  question: Question,
  headset: Headset,
  edit: Edit,
  trash: Trash,
  star: Star,
  circleXRed: CircleXRed,
  circleXWarning: CircleXWarning,
  recipientSuccess: RecipientSuccess,
  circle1: CircleOne,
  circle2: CircleTwo,
  location: Location,
  simpleCash: SimpCash,
  circleWarning: CircleWarning,
  userx: UserX,
  sendolaPayLogo: SendolaPayLogo,
  iconX: IconX,
  clock: Clock,
};

export const imgTypes = {
  login: Login,
  transferLoader: bgTransferLoader,
  bgCardSendMoney: bgCardSendMoney,
  bgConfirmation: bgConfirmation,
  bgLetterSendola,
  locationPermission,
  logoUniteller: logoUniteller,
};

export const handleSize = (size: IconSize) => {
  switch (size) {
    case "small":
      return "17px";
    case "medium":
      return "24px";
    case "large":
      return "32px";
    case "xlarge":
      return "40px";
    case "xxlarge":
      return "60px";
    case "xxxlarge":
      return "100px";
    default:
      return "18px";
  }
};

const colorMap = {
  white: theme("Background_01"),
  black: theme("Background_02"),
  grey: theme("Content_UX_02"),
  black_highlight: theme("Content_UX_01"),
  solid_light: textComponentSolidlight,
  solid_2: textComponentSolidlight,
  disabled: theme("Primary_11"),
  success: theme("Primary_06"),
  warning: theme("Primary_08"),
  error: theme("Error"),
  transparent: theme("Transparent"),
  magenta: theme("Primary_03"),
  zelle: theme("Zelle"),
  default: theme("Background_03"),
  blue: theme("BannerBank"),
};

export type FontColor = keyof typeof colorMap;

export const handleColor = (color: FontColor) => {
  return color ? colorMap[color] || colorMap["default"] : colorMap["default"];
};
