import styled, { css, CSSProperties } from "styled-components";
import { ifProp, prop, switchProp } from "styled-tools";
import {
  iconColors,
  iconWihGradient,
} from "../../Contexts/ThemeProvider/IconFont";
import { IconsFontColors } from "../../Models/IconInterface";

const IconFontStyled = styled.span<{
  size?: string;
  color?: IconsFontColors;
  gradient?: boolean;
  weight?: CSSProperties["fontWeight"];
  lineHeight?: CSSProperties["lineHeight"];
}>`
  color: ${ifProp("color", iconColors, "inherit")};
  font-size: ${switchProp("size", {
    small: "12px",
    medium: "24px",
    large: "32px",
    xlarge: "40px",
    xxlarge: "48px",
    xxxlarge: "64px",
    default: "16px",
  })};
  font-weight: ${prop("weight")};
  line-height: ${prop("lineHeight")};
  ${ifProp(
    "gradient",
    css`
      background: -webkit-${iconWihGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `
  )};
`;

type StyledParams = Parameters<typeof IconFontStyled>;
type StyledProps = StyledParams[0];

export function FontStyled(props: StyledProps) {
  return <IconFontStyled {...props} />;
}
