
      var isProdEnv = import.meta.env.PROD;

      if (isProdEnv) {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }

        const gtmScript1 = document.createElement("script");
        gtmScript1.src =
          "https://www.googletagmanager.com/gtag/js?id=AW-11415518702";
        document.head.appendChild(gtmScript1);
        gtag("js", new Date());
        gtag("config", "AW-11415518702");

        const gtmScript2 = document.createElement("script");
        gtmScript2.src =
          "https://www.googletagmanager.com/gtag/js?id=G-H1PX7Y7V1X";
        document.head.appendChild(gtmScript2);
        gtag("js", new Date());
        gtag("config", "G-H1PX7Y7V1X");

        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "GTM-WPWM9QZ9");

        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        );
        fbq("init", "738363284556902");
        fbq("track", "PageView");

        /* Hotjar Tracking Code for Sendola GP */
        (function (h, o, t, j, a, r) {
          h.hj =
            h.hj ||
            function () {
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: 5128539, hjsv: 6 };
          a = o.getElementsByTagName("head")[0];
          r = o.createElement("script");
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      }
    