import { useTranslation } from "react-i18next";
import { PaymentMethodType } from "../../../../Modules/Common/domain/PaymentMethods";
import { FlexContainer } from "../../../Flex/Flex.styled";
import {
  BankSupportedLogoContainer,
  PaymentMethodCard,
  PaymentMethodRadioContainer,
  PaymentMethodTypeTag,
  PlaidLogoElement,
  SendolaPayLogoElement,
} from "./PaymentRadio.styled";
import RadioCustom from "../../../RadioCustom";
import { GradientCard } from "../../../Card/Card.styled";
import VisaLogo from "../../../../Assets/Img/visa.png";
import MasterCardLogo from "../../../../Assets/Img/mastercard.png";
import ApplePayLogo from "../../../../Assets/Img/apple-pay.png";
import GPayLogo from "../../../../Assets/Img/google-pay.png";
import AmexLogo from "../../../../Assets/Img/amex-minified.png";
import PlaidLogos from "../../../../Assets/Img/plaid-banks-logos.png";
import SendolaPayLogos from "../../../../Assets/Img/banks-sendolapay.png";
import SendolaPayLogo from "../../../../Assets/Img/sendola-pay-short.png";
import Text from "../../../Text";
import { PropsWithChildren, ReactNode, useRef } from "react";
import IconFont from "../../../IconFont";
import { Superscript } from "../../../Text/Superscript.component";

interface PaymentRadioProps extends PropsWithChildren {
  type: PaymentMethodType;
  logo?: string;
  logoElement?: ReactNode;
  value: string;
  title?: string;
  description?: string;
  showBankSupported?: boolean;
  banksSupportedLogos?: string[];
  checked?: boolean;
  balance?: number;
  forwardRef?: React.RefCallback<HTMLInputElement>;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
}

export const PaymentRadio = ({
  type,
  logo,
  logoElement,
  value,
  title,
  description,
  showBankSupported,
  banksSupportedLogos = [
    VisaLogo,
    MasterCardLogo,
    ApplePayLogo,
    AmexLogo,
    GPayLogo,
  ],
  checked,
  children,
  forwardRef,
  onChange,
  onClick,
}: PaymentRadioProps) => {
  const [tSendMoney] = useTranslation("sendMoney");
  const radioRef = useRef<HTMLInputElement | null>(null);

  return (
    <PaymentMethodRadioContainer
      alignItems="center"
      gap="8px"
      onClick={() => {
        if (radioRef?.current) {
          radioRef.current.click();
        }
        onClick && onClick();
      }}
    >
      <FlexContainer w="20px">
        <RadioCustom
          forwardRef={(el) => {
            if (radioRef) {
              radioRef.current = el;
            }
            if (forwardRef) {
              forwardRef(el);
            }
          }}
          name="paymentMethod"
          value={value}
          checked={checked}
          onChange={(e) => onChange && onChange(e)}
        />
      </FlexContainer>

      <FlexContainer direction="column" gap="4px">
        <PaymentMethodTypeTag type={type}>
          {tSendMoney(`paymentMethods.types.${type}`)}
          <Superscript note="7" />
        </PaymentMethodTypeTag>
        <GradientCard
          borderWidth="1px"
          width="100%"
          padding="13px 15px"
          borderColor="muted"
        >
          <PaymentMethodCard>
            {!logoElement ? <img src={logo} /> : logoElement}
            <FlexContainer direction="column" gap="4px">
              {showBankSupported ? (
                <>
                  <FlexContainer
                    flex="1 1 auto"
                    justify="start"
                    alignItems="start"
                    w="100%"
                    flexWrap
                  >
                    {banksSupportedLogos.map((bankLogo, idx) => (
                      <BankSupportedLogoContainer
                        key={`bankLogo-${value}-${idx}`}
                      >
                        <img src={bankLogo} height="100%" />
                      </BankSupportedLogoContainer>
                    ))}
                  </FlexContainer>
                  <Text align="left" color="grey" size="default" weight={400}>
                    {description}
                  </Text>
                </>
              ) : (
                <>
                  {title && (
                    <Text align="left" color="black" size="default">
                      <span dangerouslySetInnerHTML={{ __html: title }}></span>
                    </Text>
                  )}
                  {description && (
                    <Text align="left" color="grey" size="default" weight={400}>
                      <span
                        dangerouslySetInnerHTML={{ __html: description }}
                      ></span>
                    </Text>
                  )}
                </>
              )}
              {children}
            </FlexContainer>
          </PaymentMethodCard>
        </GradientCard>
      </FlexContainer>
    </PaymentMethodRadioContainer>
  );
};

export const PlaidPaymentRadio = (props: PaymentRadioProps) => {
  return (
    <PaymentRadio
      {...props}
      description={undefined}
      logoElement={
        <PlaidLogoElement>
          <IconFont name="external-link" color="white" size="default" />
        </PlaidLogoElement>
      }
    >
      {props.description && (
        <Text align="left" color="grey" size="default" weight={400} as="div">
          <span
            style={{ display: "inline" }}
            dangerouslySetInnerHTML={{
              __html: props.description,
            }}
          ></span>
          <FlexContainer justify="end">
            <img src={PlaidLogos} width={109} />
          </FlexContainer>
        </Text>
      )}
    </PaymentRadio>
  );
};

export const SendolaPaymentRadio = (props: PaymentRadioProps) => {
  const [tSendMoney] = useTranslation("sendMoney");

  return (
    <PaymentRadio
      {...props}
      title={undefined}
      description={undefined}
      logoElement={
        <SendolaPayLogoElement>
          <img src={SendolaPayLogo} />
        </SendolaPayLogoElement>
      }
    >
      <FlexContainer>
        <FlexContainer
          direction="column"
          flex="1 1 auto"
          w="auto"
          p="0 8px 0 0"
        >
          <Text align="left" color="black" size="default">
            <span
              dangerouslySetInnerHTML={{
                __html: tSendMoney("paymentMethods.sendolaPay.title"),
              }}
            ></span>
          </Text>
          <Text align="left" color="grey" size="default" weight={400}>
            <span
              dangerouslySetInnerHTML={{
                __html: tSendMoney("paymentMethods.sendolaPay.description"),
              }}
            ></span>
          </Text>
        </FlexContainer>
        <img src={SendolaPayLogos} width={66} />
      </FlexContainer>
    </PaymentRadio>
  );
};
