import { useTranslation } from "react-i18next";
import {
  CardDetails,
  ContactDetails,
  AmountContainer,
  ContainerCard,
  TextContainer,
  IconCountry,
} from "./TransactionCard.styled";

import { Column, Container, Row } from "../../../Components/Grid";
import Text from "../../../Components/Text";
import {
  PaymentOrderStatus,
  TransactionHistory,
} from "../../../Modules/PaymentOrders/domain/PaymentOrder";
import { formatNumber } from "../../../Utilities/NumberUtils";
import { countrySelector } from "../../../Redux/Country/Country.slice";
import { ExchangeRate } from "../../../Modules/Common/domain/ExchangeRates";
import { useSelector } from "react-redux";
import { StatusText } from "./TransactionHistoryDetailsPage.styled";

const TransactionsCard = ({ data, getDetails, date }: TransactionHistory) => {
  const { t, i18n } = useTranslation("global");
  const countries = useSelector(countrySelector).countries as ExchangeRate[];

  return (
    <Container as={CardDetails} onClick={getDetails}>
      <Row>
        <Column span={12}>
          <ContainerCard>
            <TextContainer>
              <IconCountry>
                <img
                  src={
                    countries.find(
                      (exchange) =>
                        exchange.countryCode === data.destinationCountry
                    )?.iconUs
                  }
                />
              </IconCountry>
              <ContactDetails>
                <Text
                  size={0.5}
                  align="left"
                  color="black"
                  weight={500}
                  margin="0 0 4px 0"
                >
                  {data.contactName}
                </Text>
                <Text
                  size={"default"}
                  align="left"
                  color="grey"
                  weight={400}
                  margin="0 0 4px 0"
                >
                  {data.deliveryMethodCode === "D2B"
                    ? t("ReviewTransaction.PayOutMethod.DirectToBank")
                    : t("ReviewTransaction.PayOutMethod.CashPickUp")}
                </Text>
                <StatusText status={data.status}>
                  {date} -{" "}
                  <span>
                    {t(
                      data?.status?.toLowerCase() === PaymentOrderStatus.settled
                        ? "TransactionStatuses.Paid"
                        : data?.status?.toLowerCase() === "paid"
                        ? "TransactionStatuses.Paid"
                        : data?.status?.toLowerCase() === "canceled"
                        ? "TransactionStatuses.Cancelled"
                        : data?.status?.toLowerCase() === "pending"
                        ? "TransactionStatuses.Pending"
                        : data?.status?.toLowerCase() === "rejected"
                        ? "TransactionStatuses.Rejected"
                        : data?.status?.toLowerCase() ===
                          "available for payment"
                        ? "TransactionStatuses.Available"
                        : data?.status?.toLowerCase() === "funds"
                        ? "TransactionStatuses.Funds"
                        : data?.status?.toLowerCase() ===
                          PaymentOrderStatus.inReview
                        ? "TransactionStatuses.InReview"
                        : data?.status?.toLocaleLowerCase() ===
                          PaymentOrderStatus.acceptedByRail
                        ? "TransactionStatuses.AcceptedByRail"
                        : data?.status?.toLocaleLowerCase() === "verified"
                        ? "TransactionStatuses.Verified"
                        : data?.status?.toLocaleLowerCase() === "created"
                        ? "TransactionStatuses.Created"
                        : data?.status?.toLocaleLowerCase() === "declined"
                        ? "TransactionStatuses.Declined"
                        : data?.status || ""
                    )}
                  </span>
                </StatusText>
              </ContactDetails>
            </TextContainer>
            <AmountContainer>
              <Text
                size={0.5}
                color="black_highlight"
                weight={600}
                margin="0 0 4px 0"
                align="right"
              >
                {formatNumber(data.amountSend || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                {data.currencySend}
              </Text>
              <Text
                size={"default"}
                color="grey"
                weight={400}
                margin="0 0 4px 0"
                align="right"
              >
                {formatNumber(data.amountReceived || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                {data.currencyReceived}
              </Text>
            </AmountContainer>
          </ContainerCard>
        </Column>
      </Row>
    </Container>
  );
};

export default TransactionsCard;
